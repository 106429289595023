import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import '../styles/youtube_embed.css';
import { Select, Space, Table, Tooltip, Flex , Spin, notification, Button, Popconfirm } from 'antd';
import GalleryVideoImg from "../components/GalleryVideoImg";
import { useTranslation } from "react-i18next";
import { CaretUpOutlined, CaretDownOutlined, YoutubeOutlined, TikTokOutlined} from '@ant-design/icons';

const YoutubeEmbedButtom = ({ gallery_list }) => {

	const [visible, setVisible] = useState(false);
	const { t, i18n: {changeLanguage, language} } = useTranslation();

	const load_exc_click = () => {

		if(visible == true){
			setVisible(false);
		}else{
			setVisible(true);
		}
	}

	return(
		<>
			<div style={{width:"100%"}}>
				<div style={{maxWidth:200, margin:"auto"}}>
				<Button onClick={load_exc_click} style={{width:"200px",marginRight:"5px",padding:30}}><img src="/img/youtube_video.png" width="48" height="48"/><h2>{t('video')}</h2> {visible ? <CaretUpOutlined /> : <CaretDownOutlined />}</Button>
				</div>
			</div>
			{visible && (<div id="gallery" style={{width:"100%" }}>
				<div style={{maxWidth:903, margin:"auto"}}>
					<GalleryVideoImg gallery_list={gallery_list}/>
				</div>
			</div>)}
		</>
	);

}

export default YoutubeEmbedButtom;