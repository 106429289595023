import React, { useEffect, useState, useRef } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { Route, Link, Routes, useLocation, useNavigate } from 'react-router-dom';
import './css/index.css';
import { Flex, Layout, Button } from "antd";
import ScreenPart from './components/ScreenPart';
const { Header, Footer, Sider, Content } = Layout;
const headerStyle = {
  textAlign: 'center',
  maxHeight: "5%",
  color: '#000000',
  backgroundColor: '#FFFFFF',
  border: '1px solid rgba(0, 0, 0, 1)'
};
const contentStyle = {
  textAlign: 'center',
  minHeight: 120,
  lineHeight: '120px',
  color: '#000000',
  backgroundColor: '#FFFFFF',
  border: '1px solid rgba(0, 0, 0, 1)'
};
const siderStyle = {
    textAlign: 'center',
    lineHeight: '120px',
    color: '#000000',
  backgroundColor: '#FFFFFF',
  border: '1px solid rgba(0, 0, 0, 1)'
  };
const footerStyle = {
  textAlign: 'center',
  color: '#000000',
  backgroundColor: '#FFFFFF',
  border: '1px solid rgba(0, 0, 0, 1)',
  maxHeight: "5%",
};
const layoutStyle = {
  overflow: 'hidden',
  width: '100vh',
  maxWidth: '100vw',
  color: '#000000',
  backgroundColor: '#FFFFFF',
  border: '1px solid rgba(0, 0, 0, 1)'
};

const TLSScreen = () => {

    const navigate = useNavigate();

    const elementRef = useRef(null);
    const [screen_update, setScreenUpdate] = useState(true);
    const [screen_settings, setScreenSettings] = useState({
        sider: false,
        screen_qty: 4,
        screen_area : {
            width: 1280,
            height: 720,
            proportion_w: 16,
            proportion_h: 9
        },
        qtd_view_linha : 2,
        views : [
            {
                width: 1280/2,
                height: 720/2,
                proportion_w: 16,
                proportion_h: 9,
                visible: true,
                selected : true
            },
            {
                width: 1280/2,
                height: 720/2,
                proportion_w: 16,
                proportion_h: 9,
                visible: true,
                selected : false
            },
            {
                width: 1280/2,
                height: 720/2,
                proportion_w: 16,
                proportion_h: 9,
                visible: true,
                selected : false
            }
        ]
    });
    // const [screen_area, setScreenArea] = useState({
    //     width: 1280,
    //     height: 720
    // });
    //const [qtd_view_linha, setQtdViewLinha] = useState(Math.sqrt(screen_settings.screen_qty) > parseInt(Math.sqrt(screen_settings.screen_qty).toFixed(0)) ? parseInt(Math.sqrt(screen_settings.screen_qty).toFixed(0)) + 1 : Math.sqrt(screen_settings.screen_qty).toFixed(0))
    //const qtd_view_linha = Math.sqrt(screen_settings.screen_qty) > parseInt(Math.sqrt(screen_settings.screen_qty).toFixed(0)) ? parseInt(Math.sqrt(screen_settings.screen_qty).toFixed(0)) + 1 : Math.sqrt(screen_settings.screen_qty).toFixed(0);
    // const [view, setView] = useState({
    //     width: screen_settings.screen_area.width / qtd_view_linha,
    //     height: screen_settings.screen_area.height / qtd_view_linha,
    //     proportion_w: 16,
    //     proportion_h: 9
    // });

    useEffect(() => {

        setScreenSettings({
            ...screen_settings,
            screen_area: {
                width: elementRef.current.clientWidth,
                height: elementRef.current.clientHeight,
            },
            qtd_view_linha : Math.sqrt(screen_settings.screen_qty) > parseInt(Math.sqrt(screen_settings.screen_qty).toFixed(0)) ? parseInt(Math.sqrt(screen_settings.screen_qty).toFixed(0)) + 1 : parseInt(Math.sqrt(screen_settings.screen_qty).toFixed(0))     
        });

    }, []);

    useEffect(() => {
        // setQtdViewLinha(Math.sqrt(screen_settings.screen_qty) > parseInt(Math.sqrt(screen_settings.screen_qty).toFixed(0)) ? parseInt(Math.sqrt(screen_settings.screen_qty).toFixed(0)) + 1 : Math.sqrt(screen_settings.screen_qty).toFixed(0));
        // setView({
        //     ...view,
        //     width: screen_settings.screen_area.width / qtd_view_linha,
        //     height: ((screen_settings.screen_area.width / qtd_view_linha) / 16) * 9,
        //     proportion_w: 16,
        //     proportion_h: 9,

        // });
        //console.log(screen_settings);
        if(screen_update == true){
            setScreenUpdate(false);
            let screen_qtd = 0;
            for(let i = 0; i < screen_settings.views.length;i++){
                if(screen_settings.views[i].visible){
                    screen_qtd++;
                }
            }
            let qtd_view_linha = Math.sqrt(screen_qtd) > parseInt(Math.sqrt(screen_qtd).toFixed(0)) ? parseInt(Math.sqrt(screen_qtd).toFixed(0)) + 1 : parseInt(Math.sqrt(screen_qtd).toFixed(0));
            let views = [];
            for(let i = 0; i < screen_settings.views.length;i++){
                views.push(screen_settings.views[i]);
                views[i].width = screen_settings.screen_area.width / qtd_view_linha;
                views[i].height = ((screen_settings.screen_area.width / qtd_view_linha) / screen_settings.views[i].proportion_w) * screen_settings.views[i].proportion_h
            }
            
            setScreenSettings({
                ...screen_settings,
                screen_area: {
                    width: elementRef.current.clientWidth,
                    height: elementRef.current.clientHeight,
                },
                screen_qty: screen_qtd,
                qtd_view_linha : qtd_view_linha,
                views :  views   
            });
            console.log(screen_settings);
            
        }
        
    }, [screen_settings]);

    const commands = [
        {
            command: 'Tiago limpar tela',
            callback: ({ resetTranscript }) => resetTranscript(),
        },
        {
            command: 'Tiago pesquisar sobre * no google',
            callback: (site) => {
                //window.open(`https://www.google.com/search?q=${site}`)
                //navigate(`https://www.google.com/search?q=${site}`, {replace: false});
                window.open(`https://www.google.com/search?q=${site}`, '_blank')
            },
        },
        {
            command: 'Thiago limpar tela',
            callback: ({ resetTranscript }) => resetTranscript(),
        },
        {
            command: 'Thiago pesquisar sobre * no google',
            callback: (site) => {
                //window.open(`https://www.google.com/search?q=${site}`)
                //navigate(`https://www.google.com/search?q=${site}`, {replace: false});
                window.open(`https://www.google.com/search?q=${site}`, '_blank')
            },
        },
        {
            command: 'Tiago cor *',
            callback: (cor) => {
                document.getElementById('h1-text').style.background = cor;
                if (cor === 'Black') {
                    document.getElementById('h1-text').style.color = 'white'
                }
                else {
                    document.getElementById('h1-text').style.color = 'black'
                }
            }
        },
        {
            command: 'Thiago cor *',
            callback: (cor) => {
                document.getElementById('h1-text').style.background = cor;
                if (cor === 'Black') {
                    document.getElementById('h1-text').style.color = 'white'
                }
                else {
                    document.getElementById('h1-text').style.color = 'black'
                }
            }
        }
    ]

    const {
        transcript,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition({ commands })

    // if (!browserSupportsSpeechRecognition) return (<span>Seu navegador não é compativel com SpeechRecognition.</span>)





    const add_view = () => {
        // setScreenSettings({
        //     ...screen_settings,
        //     screen_qty: screen_settings.screen_qty+1,
        // });
        setScreenUpdate(true);
        let screen_qtd = screen_settings.screen_qty+1;
        let qtd_view_linha = Math.sqrt(screen_qtd) > parseInt(Math.sqrt(screen_qtd).toFixed(0)) ? parseInt(Math.sqrt(screen_qtd).toFixed(0)) + 1 : parseInt(Math.sqrt(screen_qtd).toFixed(0));
        let views = [];
        for(let i = 0; i < screen_settings.views.length;i++){
            views.push(screen_settings.views[i]);
            views[i].width = screen_settings.screen_area.width / qtd_view_linha;
            views[i].height = ((screen_settings.screen_area.width / qtd_view_linha) / screen_settings.views[i].proportion_w) * screen_settings.views[i].proportion_h;
            views[i].selected = false;
        }
        views.push({
            width: 1280/2,
            height: 720/2,
            proportion_w: 16,
            proportion_h: 9,
            visible: true,
            selected : true
        });

        setScreenSettings({
            ...screen_settings,
            screen_qty: screen_qtd,
            qtd_view_linha : qtd_view_linha,
            views :  views   
        });
        console.log(screen_settings);
        //setScreenUpdate(false);
	};

    const remove_view = () => {
        // setScreenSettings({
        //     ...screen_settings,
        //     screen_qty: screen_settings.screen_qty-1,
        // });
        setScreenUpdate(true);
        let screen_qtd = screen_settings.screen_qty-1;
        let qtd_view_linha = Math.sqrt(screen_qtd) > parseInt(Math.sqrt(screen_qtd).toFixed(0)) ? parseInt(Math.sqrt(screen_qtd).toFixed(0)) + 1 : parseInt(Math.sqrt(screen_qtd).toFixed(0));
        let views = [];
        for(let i = 0; i < screen_settings.views.length;i++){
            views.push(screen_settings.views[i]);
            views[i].width = screen_settings.screen_area.width / qtd_view_linha;
            views[i].height = ((screen_settings.screen_area.width / qtd_view_linha) / screen_settings.views[i].proportion_w) * screen_settings.views[i].proportion_h;
            views[i].selected = false;
        }
        views.splice(views.length-1, 1);

        setScreenSettings({
            ...screen_settings,
            screen_qty: screen_qtd,
            qtd_view_linha : qtd_view_linha,
            views :  views   
        });
        console.log(screen_settings);
	};

    const set_focus = (idx) => {
        // setScreenSettings({
        //     ...screen_settings,
        //     screen_qty: screen_settings.screen_qty-1,
        // });
        setScreenUpdate(true);
        let screen_qtd = 1;
        let qtd_view_linha = Math.sqrt(screen_qtd) > parseInt(Math.sqrt(screen_qtd).toFixed(0)) ? parseInt(Math.sqrt(screen_qtd).toFixed(0)) + 1 : parseInt(Math.sqrt(screen_qtd).toFixed(0));
        let views = [];
        for(let i = 0; i < screen_settings.views.length;i++){
            views.push(screen_settings.views[i]);
            views[i].width = screen_settings.screen_area.width / qtd_view_linha;
            views[i].height = ((screen_settings.screen_area.width / qtd_view_linha) / screen_settings.views[i].proportion_w) * screen_settings.views[i].proportion_h;
            views[i].selected = false;
            views[i].visible = (i === idx) ? true : false;
        }

        setScreenSettings({
            ...screen_settings,
            screen_qty: screen_qtd,
            qtd_view_linha : qtd_view_linha,
            views :  views   
        });
        //console.log(screen_settings);
	};

    const show_all = () => {

        setScreenUpdate(true);
        let screen_qtd = screen_settings.views.length;
        let qtd_view_linha = Math.sqrt(screen_qtd) > parseInt(Math.sqrt(screen_qtd).toFixed(0)) ? parseInt(Math.sqrt(screen_qtd).toFixed(0)) + 1 : parseInt(Math.sqrt(screen_qtd).toFixed(0));
        let views = [];
        for(let i = 0; i < screen_settings.views.length;i++){
            views.push(screen_settings.views[i]);
            views[i].width = screen_settings.screen_area.width / qtd_view_linha;
            views[i].height = ((screen_settings.screen_area.width / qtd_view_linha) / screen_settings.views[i].proportion_w) * screen_settings.views[i].proportion_h;
            views[i].visible = true;
            views[i].selected = false;
        }

        setScreenSettings({
            ...screen_settings,
            screen_qty: screen_qtd,
            qtd_view_linha : qtd_view_linha,
            views :  views   
        });
        console.log(screen_settings);
	};

    const select_screen = (idx) => {
        // setScreenSettings({
        //     ...screen_settings,
        //     screen_qty: screen_settings.screen_qty-1,
        // });
        setScreenUpdate(true);
        let screen_qtd = screen_settings.screen_qtd;
        let qtd_view_linha = Math.sqrt(screen_qtd) > parseInt(Math.sqrt(screen_qtd).toFixed(0)) ? parseInt(Math.sqrt(screen_qtd).toFixed(0)) + 1 : parseInt(Math.sqrt(screen_qtd).toFixed(0));
        let views = [];
        for(let i = 0; i < screen_settings.views.length;i++){
            views.push(screen_settings.views[i]);
            views[i].width = screen_settings.screen_area.width / qtd_view_linha;
            views[i].height = ((screen_settings.screen_area.width / qtd_view_linha) / screen_settings.views[i].proportion_w) * screen_settings.views[i].proportion_h;
            views[i].selected = (i === idx) ? true : false;
        }

        setScreenSettings({
            ...screen_settings,
            screen_qty: screen_qtd,
            qtd_view_linha : qtd_view_linha,
            views :  views   
        });
        console.log(screen_settings);
	};
    
    const show_sider = () => {
        setScreenUpdate(true);
        setScreenSettings({
            ...screen_settings,
            sider: !screen_settings.sider,
            screen_area: {
                width: elementRef.current.clientWidth,
                height: elementRef.current.clientHeight,
            }
        });
	};

    return (
        <Layout style={layoutStyle}>
            <Layout>
                <Header height="5%" style={headerStyle}>
                    <Flex>
                        <Button onClick={show_sider}>Sider</Button>
                        <Button onClick={add_view}>add_view</Button>    
                        <Button onClick={remove_view}>remove_view</Button> 
                        <Button onClick={() => set_focus(0)}>Foco primeiro</Button> 
                        <Button onClick={() => {return select_screen(1)}}>Selecionar segundo</Button>  
                        <Button onClick={show_all}>Exibir todos</Button>                    
                    </Flex>                    
                </Header>
                <Content style={contentStyle}><div>
                    <Flex id="tls_screen_content" wrap justify="center" style={{margin:'auto', backgroundColor: '#0000FF', maxWidth: screen_settings.screen_area.width, maxHeight: screen_settings.screen_area.height }} width={screen_settings.screen_area.width} height={screen_settings.screen_area.height} ref={elementRef}>
                        {/* {console.log(screen_settings.screen_area)}
                        {console.log(view)} */}
                        {
                            screen_settings.views.map((view,index)=>(
                                //console.log(view);
                                <ScreenPart view={view} key={index}/>
                            ))
                        }
                        {/* <ScreenPart view={view} />
                        
                        {screen_settings.screen_qty > 1 && <ScreenPart view={view} />}
                        {screen_settings.screen_qty > 2 && <ScreenPart view={view} />}
                        {screen_settings.screen_qty > 3 && <ScreenPart view={view} />}
                        {screen_settings.screen_qty > 4 && <ScreenPart view={view} />}
                        {screen_settings.screen_qty > 5 && <ScreenPart view={view} />}
                        {screen_settings.screen_qty > 6 && <ScreenPart view={view} />}
                        {screen_settings.screen_qty > 7 && <ScreenPart view={view} />}
                        {screen_settings.screen_qty > 8 && <ScreenPart view={view} />} */}
                    </Flex>
                    {/* <p>Exemplo: Thiago pesquisar sobre * no google</p>
            <button onClick={()=>{SpeechRecognition.startListening({continuous: true})}}>Começar a gravar</button>
            <button onClick={SpeechRecognition.stopListening}>Parar de gravar</button>
            <button onClick={resetTranscript}>Resetar campos</button>

            <h1 id="h1-text">{transcript}</h1> */}
                </div></Content>
                
                <Footer height="5%" style={footerStyle}>{!browserSupportsSpeechRecognition && <span>Seu navegador não é compativel com SpeechRecognition.</span>}</Footer>
            </Layout>
            {screen_settings.sider && <Sider width="20%" style={siderStyle}>
                Sider
            </Sider>}
        </Layout>



    )
}

export default TLSScreen;