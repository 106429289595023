import React from 'react';
import { Divider, List, Typography, Card, Flex, Carousel, ConfigProvider, Image, Col } from 'antd';
import YoutubeEmbed from "../components/YoutubeEmbed";
import { useEffect, useState } from "react";
import { RightOutlined, LeftOutlined} from '@ant-design/icons';

const GalleryVideoImg = ({ gallery_list }) => {

	function SampleNextArrow(props) {
		const { className, style, onClick } = props
		return (
		    <div
		    className={className}
		    style={{ ...style, display: "block",fontSize:24,color: "#000000",fontWeight: 'bold', marginRight:"-10px"}}
		    onClick={onClick}
		    />
		)
	 }
	 
	 function SamplePrevArrow(props) {
		const { className, style, onClick } = props
		return (
		    <div
		    className={className}
		    style={{ ...style, display: "block",fontSize:24,color: "#000000",fontWeight: 'bold', marginLeft:"-10px"}}
		    onClick={onClick}
		    />
		)
	 }
	 
	 const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
	 }

	const [padd, setPadd] = useState(25);
	//let pad = gallery_list.lenght > 0 ? 25 : 0;
	
	useEffect(() => {
		if(gallery_list.length > 1){
			setPadd(25);
		}else{
			setPadd(1);
		}
		
	}, []);

	return (
		<ConfigProvider
			theme={{
				components: {
					Carousel: {
						arrowSize: 32
					},
				},
				token: {
					colorText :"#000000",
					colorBgContainer: "#000000"
				},
			}}
		>
			<Carousel {...settings} arrows infinite={false} style={{
				padding: padd, /*backgroundColor: "#000",*/ width: "100%", maxWidth: 903,
				maxHeight: 530
			}} >
				{
					gallery_list.map((card, index) =>
						{
							if(card.video == true){
								return (
									<div key={index} style={{flex:"block"}}>
										<div  style={{flex:"block", borderStyle:"solid"}}>
											<YoutubeEmbed embedId={card.link} />
										</div>
									</div>
								)
							}else{
								return (
									<div key={index}>
										<div>
											<Flex wrap gap="middle" justify="center" align="center" style={{borderStyle:"solid"}}>
												<Image
													style={{ maxWidth: "850px", height: "auto", maxHeight: "480px", margin: "auto"}}
													src={card.link}
												/>
											</Flex>
										</div>
									</div>
								)
							}

						}
				)}

				
				
				{/* <div style={contentStyle}>
				<YoutubeEmbed embedId="BFQ5ah5LdC8"/>
				</div>
				<div style={contentStyle}>
				<YoutubeEmbed embedId="BFQ5ah5LdC8"/>
				</div>
				<div style={contentStyle}>
				<YoutubeEmbed embedId="BFQ5ah5LdC8"/>
				</div> */}
			</Carousel>
		</ConfigProvider>
		// <Carousel {...settings} arrows={true} draggable={true}>
		// 	{gallery_list.map((res, index) => {
		// 		return (
		// 		<Col xs={24} sm={24} md={24} lg={24} xl={24}>
		// 			<div
		// 			onClick={(e) => {
		// 				//setAppsKey(`${index + 1}`)
		// 			}}
		// 			>
		// 			<center>
		// 				<div
		// 				whileHover={{
		// 					scale: 1.1,
		// 					transition: { duration: 0.3 },
		// 				}}
		// 				whileTap={{ scale: 0.9 }}
		// 				>
		// 				<img
		// 					src={`${res.link}`}
		// 					className="koompiApps "
		// 					alt={res.name}
		// 				/>
		// 				<h4 className="appsName">{res.name}</h4>
		// 				</div>
		// 			</center>
		// 			</div>
		// 		</Col>
		// 		)
		// 	})}
		// 	</Carousel>
	);

};

export default GalleryVideoImg;