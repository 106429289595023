import React from "react";
import YoutubeEmbed from "../../../components/YoutubeEmbed";
import { Link } from "react-router-dom";
import Translator from "../../../components/Translator";
import { Divider, List, Typography } from 'antd';
import { useTranslation } from "react-i18next";

const Dev = () => {

	window.scrollTo(0, 0);
	const { t, i18n: {changeLanguage, language} } = useTranslation();
	
	const data = [
		<Link rel="noopener noreferrer" to={window.location.origin.toString() + '/ia/tls_text_speech'}><Translator path={'tls_text_speech'} /></Link>,
		<Link rel="noopener noreferrer" to={window.location.origin.toString() + '/novos_conceitos/tls_screen'}><Translator path={'tls_screen'} /></Link>,
		<Link rel="noopener noreferrer" to={window.location.origin.toString() + '/novos_conceitos/tls_factory'}><Translator path={'tls_factory'} /></Link>
	];

	return(
		<div>
			<h2 className="title is-1">{t('dev_info.desenvolvedor')}</h2>
			<br/>
			<hr/>
			<br/>
			
			<List
				size="large"
				header={<div>{t('dev_info.opcoes')}</div>}
				//footer={<div>Footer</div>}
				bordered
				dataSource={data}
				renderItem={(item) => <List.Item>{item}</List.Item>}
			/>
		</div>
	);
}


export default Dev;