import React, { useState } from 'react';
import { Button, Modal, Flex, message, Alert, Table } from 'antd';
import { useTranslation } from "react-i18next";
const { forwardRef, useRef, useImperativeHandle } = React;

const ModalSelNum = forwardRef((props, ref) => {

	const { t, i18n: { changeLanguage, language } } = useTranslation();

	const [columns_info, setModalColumns] = useState([]);
	const [modal_info, setModalInfo] = useState({
		visible: false,
		probab: [],
		qty_num_tot : 0,
		qty_disp : 0
	});

	const handleClose = () => {
		modal_info.alert_visible = false;
		updateModal();
	};

	const updateModal = () => {
		setModalInfo(() => {
			return {
				visible: true,
				probab: modal_info.probab,
				qty_num_tot : modal_info.qty_num_tot,
				qty_disp : modal_info.qty_disp
			};
		});
	};
	const handleOk = (e) => {
		setModalInfo(() => {
			return {
				visible: false,
				probab: [],
				qty_num_tot : 0,
				qty_disp : 0
			};
		});
	};
	const handleCancel = (e) => {
		setModalInfo(() => {
			return {
				visible: false,
				probab: [],
				qty_num_tot : 0,
				qty_disp : 0
			};
		});
	};

	useImperativeHandle(ref, () => ({

		showModalInfo(qty_result, qty_num_tot, qty_disp, qty_min_gain, prob_info) {

			let prob_json = (prob_info === undefined || prob_info === '') ? [] : JSON.parse(prob_info);
			//console.log(prob_json);

			updateColumns(qty_result, qty_num_tot, qty_disp, qty_min_gain, prob_json);
			//Remove linhas com propabilidades em faixas não premiadas
			
			for(let i = prob_json.length -1; i >=0 ; i--){
				//if(prob_json[i].qtd_comb < qty_min_gain || prob_json[i].qtd_comb < qty_result - (qty_disp - qty_num_tot)){
				if(prob_json[i].qtd_comb < qty_min_gain || prob_json[i].qtd_comb > qty_result || prob_json[i].qtd_comb < qty_result - (qty_disp - qty_num_tot)){
					prob_json.splice(i, 1);
				}
			}

			setModalInfo(() => {

				return {
					visible: true,
					probab: prob_json,
					qty_num_tot : qty_num_tot,
					qty_disp : qty_disp
				};
			});
		}

	}));

	let columns = [];
	const updateColumns = (qty_result, qty_num_tot, qty_disp, qty_min_gain, prob_json) => {
		columns = [];

		columns.push({
			title: '',
			align: 'center',
			//fixed: 'left',
			key: 0,
			width: '30vw',
			render: (_, record) => {
				return (t('desdobramentos_lot.acertando_num_01') + record.qtd_comb + t('desdobramentos_lot.acertando_num_02')+qty_num_tot+t('desdobramentos_lot.acertando_num_03'))
			}
		},
		{
			title: t('desdobramentos_lot.probab_acertar'),
			align: 'center',
			//fixed: 'left',
			key: 99,
			children: []
		});

		if(prob_json?.length > 0){

			prob_json?.map((prob, i) => {
				if(prob.qtd_comb >= qty_min_gain && prob.qtd_comb <= qty_result){
					columns[1].children.push({
						title: prob.qtd_comb,
						align: 'center',
						key : i+1,
						width: '6vw',
						render: (_, record) => {
							return (record.acuracidade[prob.qtd_comb] === 0 ? '' : (record.acuracidade[prob.qtd_comb] / record.qtd_tot_comb * 100.0).toFixed(2) < 0.01 ? (record.acuracidade[prob.qtd_comb] / record.qtd_tot_comb * 100.0).toFixed(3) + " %" : (record.acuracidade[prob.qtd_comb] / record.qtd_tot_comb * 100.0).toFixed(2) + " %")
						}
					});
				}

			});
		}

		setModalColumns(
			() => [...columns]
		)
	}

	return (
		<>
			<Modal
				title={t('desdobramentos_lot.probab_acertar')}
				open={modal_info.visible}
				onOk={handleOk}
				onCancel={handleCancel}
				okButtonProps={{
					disabled: false,
				}}
				cancelButtonProps={{
					disabled: false,
				}}
				footer=""
				width={'100%'}
				style={{ maxWidth: 1000, top: 10 }}>
				<hr />
				<br />
				<p>{t('desdobramentos_lot.escolha_numeros_01')+modal_info.qty_num_tot+t('desdobramentos_lot.escolha_numeros_02')+modal_info.qty_disp+t('desdobramentos_lot.escolha_numeros_03')}</p>
				<br />
				<Table
					columns={columns_info}
					dataSource={modal_info.probab}
					bordered
					size="small"
					scroll={{
						x: 700,
					}}
					pagination={{ hideOnSinglePage: true }}
				/>

			</Modal>
		</>
	);
});
export default ModalSelNum;