import React, { useRef, useState, useEffect } from 'react';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Alert } from 'antd';
import { Route, Link, Routes, useLocation, useNavigate } from 'react-router-dom';
//import AuthContext from "../context/AuthProvider";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useTranslation } from "react-i18next";
import useAuth from '../../hooks/useAuth';
import bcrypt from 'bcryptjs';

const formItemLayout = {
	labelCol: {
	  xs: {
	    span: 24,
	  },
	  sm: {
	    span: 10,
	  },
	},
	wrapperCol: {
	  xs: {
	    span: 24,
	  },
	  sm: {
	    span: 16,
	  },
	},
   };
   const tailFormItemLayout = {
	wrapperCol: {
	  xs: {
	    span: 24,
	    offset: 0,
	  },
	  sm: {
	    span: 16,
	    offset: 8,
	  },
	},
   };

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const RedefSenha = () => {

	window.scrollTo(0, 0);

	const { t, i18n: { changeLanguage, language } } = useTranslation();
	const { hs } = useAuth();

	const axiosPrivate = useAxiosPrivate();
	const navigate = useNavigate();
	const location = useLocation();

	const [errMsg, setErrMsg] = useState('');
	const [success, setSuccess] = useState(false);
	const [refer_id, setReferId] = useState('');

	const [pwd, setPwd] = useState('');
	const [matchPwd, setMatchPwd] = useState('');

	const [validPwd, setValidPwd] = useState(false);
	const [validMatch, setValidMatch] = useState(false);

	const [form] = Form.useForm();

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search)
		const ce = queryParams.get("ce");
		setReferId(ce);
	}, []);

	const SubmitButton = ({ form, children, on_click }) => {
		const [submittable, setSubmittable] = React.useState(false);
	
		// Watch all values
		const values = Form.useWatch([], form);
		React.useEffect(() => {
			form
				.validateFields({
					validateOnly: true,
				})
				.then(() => setSubmittable(true))
				.catch(() => setSubmittable(false));
		}, [form, values]);
		
		return (
			<Button type="primary" htmlType="submit" disabled={!submittable} onClick={on_click}>
				{children}
			</Button>
		);
	};

	useEffect(() => {
		setErrMsg('');	
		setValidPwd(PWD_REGEX.test(pwd));
		setValidMatch(pwd === matchPwd);
		setSuccess(false);
	}, [pwd, matchPwd])

	const handleSubmit = async (e) => {
		e.preventDefault();

		const v1 = PWD_REGEX.test(pwd);
		const v2 = pwd === matchPwd;
		const hashedPassword = bcrypt.hashSync(pwd, hs);

		if (!v1 || !v2) {
			setErrMsg(t("cadastro_msg.preenchimento_invalido"));
			return;
		}

		let api_ret = false;

		try {
			const response = await axiosPrivate.post('/UserPwdUpdate', {
				ri : refer_id, 
				sn : hashedPassword
			});

			if (response.status === 200) {
				//console.log(response.data);
				//setSuccess(false);
				if (response.data !== null && response.data !== undefined && response.data !== "") {
					console.log(response.data);
					switch (response.data.error_code) {
						case 0:
							setSuccess(true);
							setErrMsg("");
							break;
						//sucRef.current.focus();
						case 1:

							setErrMsg('updt_pwd.error_1');
							//setSuccess(false);
							break;
						case 2:
							setErrMsg('updt_pwd.error_2');
							//setSuccess(false);
							break;
						case 3:
							setErrMsg('updt_pwd.error_3');
							//setSuccess(false);
							break;
						default:
							setErrMsg('updt_pwd.error_4');
						//setSuccess(false);
					}

				} else {
					setErrMsg('updt_pwd.error_5');
					//setSuccess(false);
				}

			} else {
				setErrMsg('updt_pwd.error_6');
				//setSuccess(false);
			}
		} catch (err) {
			if (!err?.response) {
				setErrMsg("updt_pwd.error_7");
				setSuccess(false);
			} else if (err.response?.status === 409) {
				setErrMsg("updt_pwd.error_8");
				setSuccess(false);
			} else {
				setErrMsg("updt_pwd.error_9");
				setSuccess(false);
			}
		}
	}

	
	return (
		<div>
			<h2 className="title is-1">{t('cadastro_msg.redefinir_pwd')}</h2>
			<br />
			<hr />
			<br />
			<div 
				style={{
				//height: 300, 
				display: 'flex', 
				justifyContent: 'center', 
				alignItems: 'center'
				}}
			>
				<Form
					{...formItemLayout}
					form={form}
					//onSubmit={handleSubmit}
					name="register"
					//onFinish={handleSubmit}
					style={{
						maxWidth: 600,
					}}
					scrollToFirstError
				>
					{errMsg != "" && success === false ? (
						<><Alert message={t(errMsg)} type="error" showIcon /><br/></>
					) : (<></>)}
					
					{success === true && errMsg == "" ? (
						<><Alert message={t("cadastro_msg.redefinir_success")} type="success" showIcon/><br/></>
					) : (<></>)}

					<Form.Item
						id="password_inp"
						name="password"
						label={t("cadastro_msg.senha")}
						onChange={(e) => setPwd(e.target.value)}
						tooltip={t("cadastro_msg.senha_tooltip")}
						rules={[
							{
								required: true,
								message: t("cadastro_msg.falta_senha"),
							},
							() => ({
								validator(_, value) {
								if (PWD_REGEX.test(value)) {
									return Promise.resolve();
								}
									return Promise.reject(new Error(t("cadastro_msg.senha_err")));
								},
							}),
						]}
						hasFeedback
					>
        					<Input.Password />
      				</Form.Item>

					<Form.Item
						id="confirm_inp"
						name="confirm"
						label={t("cadastro_msg.confir_senha")}
						onChange={(e) => setMatchPwd(e.target.value)}
						dependencies={['password']}
						hasFeedback
						rules={[
							{
								required: true,
								message: t("cadastro_msg.falta_confir_senha"),
							},
							({ getFieldValue }) => ({
								validator(_, value) {
								if (!value || getFieldValue('password') === value) {
									return Promise.resolve();
								}
									return Promise.reject(new Error(t("cadastro_msg.confir_senha_err")));
								},
							}),
						]}
					>
						<Input.Password />
					</Form.Item>

					<Form.Item {...tailFormItemLayout}>
						<SubmitButton disabled={!validPwd|| !validMatch ? true : false} form={form} on_click={handleSubmit}>{t("cadastro_msg.redefinir_pwd")}</SubmitButton>
					</Form.Item>
    				</Form>
				    
    			</div>

		</div>
	)
};

export default RedefSenha