import React from "react";
import useAuth from '../hooks/useAuth';
import { useTranslation } from "react-i18next";
import { Divider, List, Typography, Card, Flex, Carousel, ConfigProvider, Image } from 'antd';
import StoreList from "../components/StoreList";
import GalleryVideoImg from "../components/GalleryVideoImg";
import { Translate } from "../services/translate";
import '../styles/terms.css';

const PreenchimLot = () => {

	window.scrollTo(0, 0);

	const { t, i18n: {changeLanguage, language} } = useTranslation();
	const { auth,habRoles } = useAuth();

	const hab_func = habRoles(['5D4F7428-4366-4453-8CBC-394C9B6B7419']);

	const gallery_list = [
		{
			"link" : "EuCniQqChRA",
			"video" : true
		},
		{
			"link" : "3qr9Xf0_Fsc",
			"video" : true
		},
		{
			"link" : "/img/preenchimento_loteria/img_01.png",
			"video" : false
		},
		{
			"link" : "/img/preenchimento_loteria/img_02.png",
			"video" : false
		},
		{
			"link" : "/img/preenchimento_loteria/img_03.png",
			"video" : false
		}
	];

	const contentStyle = {
		margin: 0,
		width: '100%',
		padding: 50,
		color: '#fff',
		lineHeight: '160px',
		textAlign: 'center',
		background: '#364d79',
	   };

	return(
		<div>
			<h2 className="title is-1">{t('preenchimento_loteria')}</h2>
			<br/>
			<hr/>
			<br/>
			<StoreList 
				//chrome_store={"https://chromewebstore.google.com/detail/ggogoinhjigamdpkogcjgkmlgcknlflb/preview?hl=pt-BR&authuser=1&pli=1"} 
				google_play={""} 
				apple_store={""}
				microsoft_store={"https://microsoftedge.microsoft.com/addons/detail/iplddpjbdleoenmocdkfhjjkchelpjan"}
				download={"/downloads/TLS_Loterias.zip"}
				hab_download={hab_func}
				user={auth?.user}
			/>
			<br/>
			<div style={{width:"100%"}}>
				<div style={{maxWidth:903, margin:"auto"}}>
					<GalleryVideoImg gallery_list={gallery_list}/>
				</div>
			</div>
			<br/>
			<br/>
			<Flex wrap gap="middle" justify="center" align="center">
			<List
				style={{maxWidth:1200}}
				size="large"
				header={<h2 className="title is-1">{t('visao_geral')}</h2>}
				bordered
				dataSource={[<></>]}
				renderItem={(item)=> <List.Item>{
					language == "pt-BR" ?
						(<>
							<div className='term_just'>Aumente sua eficiência ao preencher jogos de loteria com a extensão da TLS Engenharia para Google Chrome!<br/>
							Ideal para quem possui uma lista de apostas (Mega-Sena, Lotofácil, Lotomania, Quina e Dupla Sena) em uma planilha do Excel, esta extensão facilita o processo de importação e revisão no site oficial da Caixa Econômica Federal, loterias online.
							<br/>
							<br/>
							<h3>Recursos:</h3><br/>
							<ul>
							<li><strong>Importação de Planilhas:</strong> Carregue suas listas de apostas do Excel diretamente na extensão.</li>
							<li><strong>Adição ao Carrinho:</strong> Preencha automaticamente o carrinho de apostas no site da Caixa com base nas suas planilhas.</li>
							{/* <li><strong>Revisão Antes do Pagamento:</strong> Verifique todos os jogos no carrinho antes de prosseguir para o pagamento, garantindo precisão e controle total.</li> */}
							<li><strong>Interface Amigável:</strong> Configuração e uso intuitivos, sem necessidade de conhecimentos técnicos avançados.</li>
							</ul>
							<br/>
							Simplifique e otimize o processo de apostas com o preenchimento Automático de Loteria. Instale agora e experimente a facilidade de gerenciar suas apostas com precisão e eficiência!</div>
						</>) : 
					language == "es" ? 
						(<div className='term_just'>
							¡Aumenta tu eficiencia al completar boletos de lotería con la extensión TLS Engenharia para Google Chrome!<br/>
							Ideal para quienes tienen una lista de apuestas (Mega-Sena, Lotofácil, Lotomania, Quina y Dupla Sena) en una hoja de cálculo de Excel, esta extensión facilita el proceso de importación y revisión en el sitio oficial de la Caixa Econômica Federal, loterías en línea.
							<br/>
							<br/>
							<h3>Recursos:</h3><br/>
							<ul>
							<li><strong>Importación de Hojas de Cálculo:</strong> Carga tus listas de apuestas desde Excel directamente en la extensión.</li>
							<li><strong>Agregar al Carrito:</strong> Llena automáticamente el carrito de apuestas en el sitio web de Caixa con base en tus hojas de cálculo.</li>
							{/* <li><strong>Revisión Antes del Pago:</strong> Revisa todas las apuestas en el carrito antes de proceder al pago, asegurando precisión y control total.</li> */}
							<li><strong>Interfaz Amigable:</strong> Configuración y uso intuitivo, sin necesidad de conocimientos técnicos avanzados.</li>
							</ul>
							<br/>
							¡Simplifica y optimiza el proceso de apuestas con el llenado automático de lotería! Instálalo ahora y experimenta la facilidad de gestionar tus apuestas con precisión y eficiencia.
						 </div>) : 
						(<div className='term_just'>
							Increase your efficiency when filling lottery tickets with TLS Engenharia's extension for Google Chrome!<br/>
							Ideal for those who have a list of bets (Mega-Sena, Lotofácil, Lotomania, Quina and Dupla Sena) in an Excel spreadsheet, this extension makes the import and review process easier on the official Caixa Econômica Federal lottery website, online lotteries.
							<br/>
							<br/>
							<h3>Features:</h3><br/>
							<ul>
							<li><strong>Spreadsheet Import:</strong> Upload your bet lists from Excel directly into the extension.</li>
							<li><strong>Add to Cart:</strong> Automatically fill the bet cart on Caixa's website based on your spreadsheets.</li>
							{/* <li><strong>Review Before Payment:</strong> Check all bets in the cart before proceeding to payment, ensuring precision and full control.</li> */}
							<li><strong>User-Friendly Interface:</strong> Intuitive setup and use, no advanced technical knowledge required.</li>
							</ul>
							<br/>
							Simplify and optimize your betting process with Automatic Lottery Filling. Install now and experience the ease of managing your bets with precision and efficiency!
						 </div>)
					}
					</List.Item>}
			/>
			</Flex>
		</div>
	);
}


export default PreenchimLot;