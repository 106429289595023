import React, { useEffect, useState, Component, useMemo, useCallback, useTransition } from 'react';
import { Flex, Result } from 'antd';
import Card from "../components/Card";
//import { Translate } from "../services/translate";
import { useTranslation } from "react-i18next";
//import useAuth from '../hooks/useAuth';

const Home = ({ items }) => {

	window.scrollTo(0, 0);

	const { t, i18n: {changeLanguage, language} } = useTranslation();

	return (
		<div>
			<h2 className="title is-1">Home</h2>
			<br />
			<hr />
			<br />
			{/* {items === null || items === undefined || items?.lenght == 0 ? (
						<><Result message={t("login")} type="error" showIcon /><br /></>
					) : (<></>)} */}
			<Flex wrap gap="small" justify="center">
				{
					items.map((card, index) =>
						(<Card key={index + 1} title={card.label} image={card.img} text={""} route={card.route} />)
				)}
			</Flex>
		</div>
	)
};

export default Home