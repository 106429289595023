import React from "react";
import useAuth from '../hooks/useAuth';
import { useTranslation } from "react-i18next";
import TLSScreen from "../components/TLSScreen/TLSScreen";

const TLSScreenPage = () => {

	window.scrollTo(0, 0);

	const { t, i18n: {changeLanguage, language} } = useTranslation();
	const { auth } = useAuth();

	return(
		<div>
			<h2 className="title is-1">TLS Screen</h2>
			<br/>
			<hr/>
			<br/>
			<TLSScreen/>
		</div>
	);
}


export default TLSScreenPage;