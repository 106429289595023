import React from 'react';
// import { Link } from 'react-router-dom';
// import { Space  } from 'antd';
// import '../styles/card.css';
// import { useTranslation } from "react-i18next";
import YoutubeEmbedView from "./YoutubeEmbedView";

const ScreenPart = ({view}) => {

	//const { t, i18n: {changeLanguage, language} } = useTranslation();
	//console.log(view)

	return (
		<>
			<div 
				style={{width: view.width+'px', height: (view.height+(view.selected == true ? 2 : 0))+'px', border: (view.selected == true ? '3' : '1')+'px solid '+(view.selected == true ? '#00FF00' : 'black'), backgroundColor: 'white', display: (view.visible ? 'block' : 'none')}}
			>
				<YoutubeEmbedView embedId={"_lS1HFFwyD0"} />
			</div>
		</>
	);
    
};

export default ScreenPart;