import React from "react";
import useAuth from '../hooks/useAuth';
import { useTranslation } from "react-i18next";
import TLSTextSpeech from "../components/TLSTextSpeech/TLSTextSpeech";

const TLSTextSpeechPage = () => {

	window.scrollTo(0, 0);

	const { t, i18n: {changeLanguage, language} } = useTranslation();
	const { auth } = useAuth();

	return(
		<div>
			<h2 className="title is-1">TLS Text Speech</h2>
			<br/>
			<hr/>
			<br/>
			<TLSTextSpeech/>
		</div>
	);
}


export default TLSTextSpeechPage;