import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { AutoComplete, Button,  Cascader, Checkbox, Col, Form, Input, InputNumber, Row, Select, Alert, Modal, Flex } from 'antd';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import bcrypt from 'bcryptjs';
import Terms from '../../components/Terms';

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 10,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const CadastroInfo = () => {

	window.scrollTo(0, 0);
	const { t, i18n: {changeLanguage, language} } = useTranslation();
	
	const { auth, setAuth, getSessionId } = useAuth();
	const axiosPrivate = useAxiosPrivate();
	let navigate = useNavigate();

	const userRef = useRef();
	const errRef = useRef();
	const sucRef = useRef();
 
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');	

	const [validName, setValidName] = useState(false);
	const [validEmail, setValidEmail] = useState(false);
	
	const [errMsg, setErrMsg] = useState('');
	const [success, setSuccess] = useState('');

	const [form] = Form.useForm();
	// const onFinish = async (values) => {
	// 	//this.preventDefault();
	// 	//form.preventDefault();
	//  	console.log('Received values of form: ', values);
	// 	setErrMsg('No Server Response');
	// 	//errRef.current.focus();
	// };

	useEffect(() => {
		// const queryParams = new URLSearchParams(window.location.search)
    		// const em = queryParams.get("email");
		// setEmail(em);
		// form.setFieldValue("email_inp", em);
		// if(em != ""){
		// 	//handleSubmit(emailRef);
		// }
		//Update user info
		getUserInfo();
	}, []);

	const getUserInfo = async () => {

		let si = getSessionId();

		try {
			const response = await axiosPrivate.post('/UserInfo', { si: si });

			if (response.status === 200) {
				//console.log(response.data);
				//setSuccess(false);
				if (response.data !== null && response.data !== undefined && response.data !== "") {
					//console.log(response.data);
					switch (response.data.error_code) {
						case 0:
							setName(response.data.name);
							setEmail(response.data.email);
							form.setFieldValue("nome", response.data.name);
							form.setFieldValue("email", response.data.email);
							setSuccess('');
							setErrMsg('');
							break;
						//sucRef.current.focus();
						case 1:

							setErrMsg('cadastro_info.error_1');
							//setSuccess(false);
							break;
						case 2:
							setErrMsg('cadastro_info.error_2');
							//setSuccess(false);
							break;
						default:
							setErrMsg('cadastro_info.error_4');
						//setSuccess(false);
					}

				} else {
					setErrMsg('cadastro_info.error_5');
					//setSuccess(false);
				}

			} else {
				setErrMsg('cadastro_info.error_6');
				//setSuccess(false);
			}

		} catch (err) {
			if (!err?.response) {
				setErrMsg('cadastro_info.error_7');
				//setSuccess(false);
			} else if (err.response?.status === 409) {
				setErrMsg('cadastro_info.error_8');
				//setSuccess(false);
			} else {
				setErrMsg('cadastro_info.error_9');
				//setSuccess(false);
			}
		}

	};

	const redefPwd = async () => {

		try {
			const response = await axiosPrivate.post('/RedefinirSenha', { em : auth?.user});
			//console.log(response.data);
			if (response.status === 200) {
				
				//setSuccess(false);
				if (response.data !== null && response.data !== undefined && response.data !== "") {

					switch (response.data.error_code) {
						case 0:
							setSuccess('valid_msg.email_redef_senha');
							setErrMsg('');
							break;
						//sucRef.current.focus();
						case 1:
							//E-mail inválido
							setErrMsg('valid_msg.erro_1');
							setSuccess("");
							break;
						case 4:
							//Falha ao redefinir a senha
							setErrMsg('valid_msg.erro_2');
							setSuccess("");
							break;
						default:
							setErrMsg('valid_msg.erro_3');
						//setSuccess(false);
					}

				} else {
					setErrMsg('valid_msg.erro_4');
					//setSuccess(false);
				}

			} else {
				setErrMsg('valid_msg.erro_5');
				//setSuccess(false);
			}

		} catch (err) {
			if (!err?.response) {
				setErrMsg('valid_msg.error_7');
				//setSuccess(false);
			} else if (err.response?.status === 409) {
				setErrMsg('valid_msg.error_8');
				//setSuccess(false);
			} else {
				setErrMsg('valid_msg.error_9');
				//setSuccess(false);
			}
		}
	};

	const SubmitButton = ({ form, children, on_click }) => {
		const [submittable, setSubmittable] = React.useState(false);
	
		// Watch all values
		const values = Form.useWatch([], form);
		React.useEffect(() => {
			form
				.validateFields({
					validateOnly: true,
				})
				.then(() => setSubmittable(true))
				.catch(() => setSubmittable(false));
		}, [form, values]);
		
		return (
			<Button type="primary" htmlType="submit" disabled={!submittable} onClick={on_click}>
				{children}
			</Button>
		);
	};

	useEffect(() => {
		setErrMsg('');
		setSuccess("");
		setValidName(name !== "");
		setValidEmail(email !== "");				
	}, [name, email])

	const handleSubmit = async (e) => {
		e.preventDefault();

		const v1 = name !== "";
		const v2 = email !== "";

		if (!v1 || !v2) {
			setErrMsg(t("cadastro_msg.preenchimento_invalido"));
			return;
		}

		let api_ret = false;

		try {
			const response = await axiosPrivate.post('/UserInfoUpdt', {
				si : getSessionId(),
				nm : name
			});

			if (response.status === 200) {

				if(response.data.length > 0){
					let return_info = JSON.parse(response.data[0].jsonInfo);
					switch(return_info[0].error_code) {
						case 0:
							setSuccess('cadastro_msg.info_atualizadas');
							break;
						case 1:
							setErrMsg("cadastro_msg.falha_envio");
							setSuccess(false);
							break;
						case 2:
							setErrMsg("cadastro_msg.email_cadastrado");
							setSuccess(false);
							break;
						case 3:
							setErrMsg("cadastro_msg.error_3");
							setSuccess(false);
							break;
						default:
							setErrMsg("cadastro_msg.error_4");
							setSuccess(false);
					 }

				}else{
					setErrMsg("cadastro_msg.error_5");
					setSuccess(false);
				}

			} else {
				setErrMsg("cadastro_msg.error_6");
				setSuccess(false);
			}

		} catch (err) {
			if (!err?.response) {
				setErrMsg("cadastro_msg.falha_comunicacao");
				setSuccess(false);
			} else if (err.response?.status === 409) {
				setErrMsg("cadastro_msg.error_8");
				setSuccess(false);
			} else {
				setErrMsg("cadastro_msg.error_9");
				setSuccess(false);
			}
		}
	}

	return(  
		<div>
			<h2 className="title is-1">{t("cadastro_msg.cadastro_info")}</h2>
			<br/>
			<hr/>
			<br/>
			<div 
				style={{
				//height: 300, 
				display: 'flex', 
				justifyContent: 'center', 
				alignItems: 'center'
				}}
			>
				<Form
					{...formItemLayout}
					form={form}
					//onSubmit={handleSubmit}
					name="register"
					//onFinish={handleSubmit}
					style={{
						maxWidth: 600,
					}}
					scrollToFirstError
				>
					{errMsg != "" && success === "" ? (
						<><Alert ref={errRef} message={t(errMsg)} type="error" showIcon /><br/></>
					) : (<></>)}
					
					{success !== "" && errMsg == "" ? (
						<><Alert ref={sucRef} message={t(success)} type="success" showIcon/><br/></>
					) : (<></>)}

					<Form.Item
						id="name_inp"
						name="nome"
						label={t("cadastro_msg.nome_completo")}
						onChange={(e) => setName(e.target.value)}
						maxLength={200}
						rules={[
							{
								required: true,
								message: t("cadastro_msg.nome_completo_err"),
								whitespace: true
							},
							() => ({
								validator(_, value) {
								if (value !== "") {
									return Promise.resolve();
								}
									return Promise.reject()//new Error('A confirmação está diferente da senha!'));
								},
							}),
						]}
      				>
        					<Input />
      				</Form.Item>

					<Form.Item
						id="email_inp"
						name="email"
						label={t("cadastro_msg.email")}
						onChange={(e) => setEmail(e.target.value)}
						maxLength={255}
						rules={[
							{
								required: true,
								type: 'email',
								message: t("cadastro_msg.email_err"),
							},
							() => ({
								validator(_, value) {
								if (value !== "") {
									return Promise.resolve();
								}
									return Promise.reject(new Error(t("cadastro_msg.falta_email")));
								},
							}),
						]}
					>
        					<Input readOnly={true} disabled={true}/>
      				</Form.Item>

					<Form.Item {...tailFormItemLayout}>
						<Flex gap="small">
							<SubmitButton disabled={!validName || !validEmail  ? true : false} form={form} on_click={handleSubmit}>{t("cadastro_msg.salvar")}</SubmitButton>
							<Button type="primary" form={form} onClick={redefPwd}>{t("cadastro_msg.redefinir_pwd")}</Button>
						</Flex>
						
					</Form.Item>
					{/* <Form.Item {...tailFormItemLayout}>
						<SubmitButton disabled={!validName || !validEmail  ? true : false} form={form} on_click={handleSubmit}>{t("cadastro_msg.redefinir")}</SubmitButton>
					</Form.Item> */}
    				</Form>
				    
    			</div>
          </div>  
	)
};

export default CadastroInfo