import React from 'react';
import { Flex, Popconfirm } from 'antd';
import { useTranslation } from "react-i18next";
import { Route, Link, Routes, useLocation, useNavigate } from 'react-router-dom';
import { LoadingOutlined, InfoCircleOutlined, DownloadOutlined, StopOutlined } from '@ant-design/icons';

const StoreList = ({chrome_store, google_play, apple_store, microsoft_store, download, hab_download, user}) => {

	const { t, i18n: {changeLanguage, language} } = useTranslation();

	return (
		<Flex wrap gap="middle" justify="center" align="center">
			{ chrome_store != "" && chrome_store != undefined &&<a href={chrome_store} target="_blank"><img src="/img/chrome-store.png" alt="Chrome web store" style={{width: (200)}}/></a>}
			{ google_play != "" && google_play != undefined &&<a href={google_play} target="_blank"><img src="/img/google-play.png" alt="Google play" style={{width: (200)}}/></a>}
			{ apple_store != "" && apple_store != undefined &&<a href={apple_store} target="_blank"><img src="/img/apple-store.png" alt="Apple store" style={{width: (200)}}/></a>}
			{ microsoft_store != "" && microsoft_store != undefined &&<a href={microsoft_store} target="_blank"><img src="/img/microsoft-store.png" alt="Microsoft store" style={{width: (200)}}/></a>}
			{ download != "" && download != undefined ?
				hab_download ? 
					<a href={download}  download><img src="/img/download.png" alt="Download" style={{width: (200)}}/></a> :
					<Popconfirm
						icon={<InfoCircleOutlined/>}
						placement="left"
						title={t('requer_assinatura')}
						description={t('requer_assinatura_texto')}
						//onConfirm={confirm}
						okText={<Link target="_self" rel="noopener noreferrer" to={window.location.origin.toString() + '/assinaturas'}>{t('assinaturas')}</Link>}
						cancelText={t('fechar')}
						>
						<a><img src="/img/download.png" alt="Download" style={{width: (200), opacity:  0.5}}/></a>
					</Popconfirm>
				: ""
			}
			
		</Flex>
	);
    
};

export default StoreList;