import React, { useEffect, useState, useRef } from "react";
import { Flex, Layout, Button, Menu, Switch } from "antd";
import { Canvas, useFrame } from '@react-three/fiber'
import { Physics } from "@react-three/cannon";
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { Routes, NavLink, withRouter, Route, Rediresct, useLocation, BrowserRouter, Outlet, Link } from "react-router-dom";
import { Suspense } from 'react'
import {
	ContactShadows,
	Environment,
	OrbitControls,
	PerspectiveCamera,
} from "@react-three/drei";


import { Slippers } from "./models/Slippers";
import { CncLathe } from "./models/CncLathe";
import { BenchDrill } from "./models/BenchDrill";
import { Robot } from "./models/Robot";
import { RouterCnc } from "./models/RouterCnc";



const { Header, Footer, Sider, Content } = Layout;



const items = [
	{
		label: 'Planejamento',
		key: '1',
		icon: <AppstoreOutlined />,
		children: [
			{
				label: 'Pedidos',
				key: '2'
			},
			{
				label: 'Cronograma',
				key: '3'
			},
			{
				label: 'Produtividade',
				key: '4'
			},
			{
				label: 'OEE',
				key: '5'
			}
		]
	},
	{
		label: 'Produtos',
		key: '6',
		icon: <AppstoreOutlined />,
		children: [
			{
				label: 'Matéria-prima',
				key: '7'
			},
			{
				label: 'Produto semiacabado',
				key: '8'
			},
			{
				label: 'Produto acabado',
				key: '9'
			},
			{
				label: 'Estoque',
				key: '10'
			}
		]
	},
	{
		label: 'Estrutura',
		key: '11',
		icon: <AppstoreOutlined />,
		children: [
			{
				label: 'Fábricas',
				key: '12',
			},
			{
				label: 'Turnos',
				key: '13',
			},
		],
	},
	{
		label: 'Recursos',
		key: '14',
		icon: <AppstoreOutlined />,
		children: [
			{
				label: 'Equipamentos',
				key: '15',
			},
			{
				label: 'Colaboradores',
				key: '16',
			},
		],
	},
];

const headerStyle = {
	textAlign: 'center',
	height: 64,
	lineHeight: '64px',
	padding: 0,
	border: '1px solid #006EDF'
};
const contentStyle = {
	textAlign: 'center',
	minHeight: 120,
	lineHeight: '120px',
	color: '#000000',
	backgroundColor: '#0958d9',
	border: '1px solid #006EDF'
};
const siderStyle = {
	textAlign: 'center',
	lineHeight: '120px',
	color: '#000000',
	backgroundColor: '#FFFFFF',
	border: '1px solid #006EDF'
};
const footerStyle = {
	textAlign: 'center',
	color: '#000000',
	backgroundColor: '#FFFFFF',
	border: '1px solid #006EDF'
};

let current_page = "Home";

function Box(props) {
	// This reference will give us direct access to the mesh
	const meshRef = useRef()
	// Set up state for the hovered and active state
	const [hovered, setHover] = useState(false)
	const [active, setActive] = useState(false)
	// Subscribe this component to the render-loop, rotate the mesh every frame
	useFrame((state, delta) => (meshRef.current.rotation.x += delta))
	// Return view, these are regular three.js elements expressed in JSX
	return (
		<mesh
			{...props}
			ref={meshRef}
			scale={active ? 1.5 : 1}
			//onClick={(event) => setActive(!active)}
			onClick={(event) => {current_page = 'Factories'; console.log('Teste')}	}		
			onPointerOver={(event) => setHover(true)}
			onPointerOut={(event) => setHover(false)}>
			<boxGeometry args={[1, 1, 1]} />
			<meshStandardMaterial color={hovered ? 'hotpink' : 'orange'} />
		</mesh>
	)
}

// Geometry
function GroundPlane() {
	return (
	  <mesh receiveShadow rotation={[Math.PI/2, 0, 0]} position={[0, -0.1, 0]}>
	    <boxGeometry attach="geometry" args={[10, 10, 0.1]} />
	    <meshStandardMaterial attach="material" color="blue" />
	  </mesh>
	);
   }

const obj_list = [
	{
		"type": "CncLathe",
		"position": [0, 0.9, 0]
	},
	{
		"type": "BenchDrill",
		"position": [3, 0.9, 0]
	},
	{
		"type": "Robot",
		"position": [0, 0.9, 3]
	},
	{
		"type": "RouterCnc",
		"position": [3, 0.9, 3]
	},
	{
		"type": "Box",
		"position": [-3, 0.9, 3]
	},
]

const returnObj = (obj, idx) => {
	switch (obj.type) {
		case "CncLathe":
			return <CncLathe position={obj.position} key={idx} />
		case "BenchDrill":
			return <BenchDrill position={obj.position} key={idx} />
		case "Robot":
			return <Robot position={obj.position} key={idx} />
		case "RouterCnc":
			return <RouterCnc position={obj.position} key={idx} />
		case "Box":
			return <Box position={obj.position} key={idx} />
		default:
			return null
	}
}

const TLSManufacture = () => {

	const [current_component, setCurrentComponent] = useState('Home');
	const [current, setCurrent] = useState('1');
	const onClick = (e) => {
		console.log('click ', e);
		setCurrent(e.key);
	};

	useEffect(() => {
		setCurrentComponent(() => {return current_page})
		console.log('Abc');
	}, [current_page]);

	return (
		// <Layout  style={{ width: '100%', height: '100%' }}>
		// 	
		// </Layout>
		<Layout style={{ width: '100%', minHeight: '80vh' }}>
			<Header style={headerStyle}>
				<Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} style={{ flex: 1, width: '100%', float: "left" }} />
			</Header>
			<Layout>
				<Content style={contentStyle}>
					{/* <Canvas width="100%" height='100%' style={{ width: '100%', height: '100%' }}>
						<ambientLight intensity={Math.PI / 2} />
						<spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} decay={0} intensity={Math.PI} />
						<pointLight position={[-10, -10, -10]} decay={0} intensity={Math.PI} />
						<Box position={[-1.2, 0, 0]} />
						<Box position={[1.2, 0, 0]} />

						<Suspense fallback={null}>
							 <CncLathe/> 
							<Environment preset="sunset" background />
						</Suspense>
					</Canvas> */}
					{current_component == 'Home' && <Canvas
						style={{
							width: "100%",
							height: "100%",
						}}
					>
						<GroundPlane />
						<color attach="background" args={["#eee"]} />
						<Environment preset="studio" />
						<PerspectiveCamera makeDefault position={[6, 8, 2]} />
						{/* {true && <OrbitControls enableZoom={true} enablePan={true} makeDefault />} */}
						{true && <OrbitControls enableZoom={true} enablePan={true} minPolarAngle={0} maxPolarAngle={Math.PI / 2.25} makeDefault />}
						<Suspense fallback={null}>
        					<Physics>
						{
							obj_list.map((card, index) => {
								return returnObj(card, index)
							}
								//(<CncLathe position={card.position} key={index}/>)
							)}





						{/* <Slippers position={[3, 0.1, 0]} /> */}
						{/* <CncLathe position={[0, 0.1, 0]} />
						<BenchDrill position={[3, 0.1, 0]} />
						<Robot position={[0, 0.1, 3]} />
						<RouterCnc position={[3, 0.1, 3]} /> */}
						</Physics>
						</Suspense>
						<ContactShadows />
					</Canvas>}
				</Content>
				<Sider
					theme="light"
					style={{ border: '1px solid #006EDF' }}
					breakpoint="lg"
					collapsedWidth="0"
					onBreakpoint={(broken) => {
						console.log(broken);
					}}
					onCollapse={(collapsed, type) => {
						console.log(collapsed, type);
					}}
				>
					<div className="demo-logo-vertical" />
					<Menu theme="light" mode="inline" defaultSelectedKeys={['4']} items={items} />
				</Sider>
			</Layout>
			<Footer style={footerStyle}>
				<Switch>
					<Route path="admin" element={<div>A</div>} />
					<Route path="admin2" element={<div>B</div>} />
				</Switch>
			</Footer>
		</Layout>
	)
}

export default TLSManufacture;