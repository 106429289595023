import React, { useEffect, useState, Component, useMemo, useCallback, useTransition } from 'react';
import { useCookies } from 'react-cookie';
import { Flex, Layout, Space, Spin} from 'antd';
import { LoadingOutlined, InstagramOutlined, YoutubeOutlined, TikTokOutlined} from '@ant-design/icons';
import { Routes, NavLink, withRouter, Route, Rediresct, useLocation, BrowserRouter, Outlet, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";

//Services e componentes
import RequireAuth from './services/RequireAuth';
import { Location_path } from "./utils/location_info";
import StringIco from "./utils/string_ico";
import SideMenu from "./components/SideMenu";
import Navbar from "./components/Navbar";
import Translator from "./components/Translator";
import useAxiosPrivate from "./hooks/useAxiosPrivate";
import useAuth from './hooks/useAuth';
import PersistLogin from './components/PersistLogin';
import CookieConsent from './components/CookieConsent';

//Pages
import NotFound from "./pages/not_found";
import Home from "./pages/home";
import Terms from "./pages/terms";
import SubCategoria from './pages/sub_categoria';
import DesdobramentosLoteria from './pages/desdobramentos_loteria';
import ApostasEsportivasEstatisticas from './pages/apostas_esportivas_estatisticas';
import AnalisPadr from './pages/analise_padroes_loteria';
import GerarCombin from './pages/gerar_combinacoes_loteria';
import PreenchimLot from './pages/preenchimento_loteria';
import TLSScreenPage from './pages/tls_screen';
import TLSTextSpeechPage from './pages/tls_text_speech';
import TLSFactoryPage from './pages/tls_factory';


import Login from './pages/user/login';
import ResgatarCupom from './pages/user/resgatar_cupom';
import Cadastro from './pages/user/cadastro';
import CadastroInfo from './pages/user/cadastro_info';
import Contato from './pages/contato';
import Assinaturas from './pages/assinaturas';
import AssinarPlano from './pages/user/assinar_plano';
import MinhasAssinaturas from './pages/user/minhas_assinaturas';
import Admin from './pages/user/admin/admin';
import DesdobramentosAdmin from './pages/user/admin/desdobramentos_loteria_admin';
import AdminDash from './pages/user/admin/dashboard';
import Cupons from './pages/user/admin/cupons';
import Dev from './pages/user/dev/dev';
import VerificarEmail from './pages/user/verificar_email';
import ConfirmEmail from './pages/user/confirmacao_email';
import RedefSenha from './pages/user/redefinir_senha';
import AlteracaoSenha from './pages/user/alteracao_senha';



const { Header, Content, Sider, Footer } = Layout;

let api_exc = false;
let side_menu = [];
let api_return = false;


const ROLES = {
	'Developer': 'FAA61657-ECBE-4FF7-A376-CF5CF4FB930A',
	'Admin': 'DF25C130-C51F-4002-96B0-ED31DE1BD2B7',
	'User': 'A7E114C4-FB30-4E57-B523-33B8983211A7'
}

function App() {

	if(process.env.NODE_ENV === 'production'){
		if (window.location.protocol === 'http:') {
			window.location.href = window.location.href.replace('http:', 'https:');
		}
	}

	const { t, i18n: { changeLanguage, language } } = useTranslation();
	const [cookies] = useCookies(["cookieConsent"]);

	const axiosPrivate = useAxiosPrivate();
	const { auth, setAuth } = useAuth();

	const [load, setLoad] = useState(true);
	const [side_menu_info, setSideMenu] = useState([]);

	useEffect(() => {
		setSideMenu(() => { return [...side_menu] });
		setLoad(() => { return false });
	}, [load, language, auth]);

	const loadSideMenu = (page_info) => {

		let idx_key = 0;
		side_menu = [];
		//Gera as informações para o menu lateral
		if (page_info !== null && page_info !== undefined) {

			page_info.menu_info?.forEach((item) => {

				let sub_items = [];
				if (item.sub_itens.length > 0) {
					item.sub_itens.forEach((sub_item) => {
						sub_items.push({
							key: idx_key,
							label: (sub_item.route !== "") ? (<Link target="_self" rel="noopener noreferrer" to={window.location.origin.toString() + sub_item.route}><Translator path={sub_item.name} /></Link>) : <Translator path={sub_item.name} />,
							icon: null,//(sub_item.ico !== "") ? <StringIco str={sub_item.ico} /> : null,
							name: sub_item.name,
							img: sub_item.img,
							route: sub_item.route
						});
						idx_key++;
					});
				}

				side_menu.push({
					key: idx_key,
					label: (<Translator path={item.name} />),
					icon: (item.ico !== "") ? <StringIco str={item.ico} /> : null,
					name: item.name,
					children: (sub_items.length > 0) ? sub_items : null,
					img: item.img,
					route: item.route
				});
				idx_key++;
			});
		}
		api_return = true;
	}

	const onLoadPage = async () => {

		if (api_exc === false) {

			api_exc = true;
			
			try {
				const response = await axiosPrivate.post('/PageInfo', { em: '', sn: '' }).catch(()=>{});

				//console.log('PageInfo');

				if (response.status === 200) {

					if (response.data !== null && response.data !== undefined && response.data !== "" && response.data.error_code == 0) {
						setAuth({ user: response.data.user, access_token: response.data.access_token, menu_info: response.data.menu_info });
					}else{
						setAuth({ user: "", access_token: "", menu_info: [] });
					}
				} else {
					setAuth({ user: "", access_token: "", menu_info: [] });
				}

			} catch (err) {
				console.log('Erro ao carregar informações');
			}

		}
	};
	if (auth?.user === undefined) {
		onLoadPage();
	} else {
		loadSideMenu(auth);
	}

	return (

		<Spin spinning={load} indicator={<LoadingOutlined style={{ fontSize: 96, textAlign: 'center', justify:"center" }} spin />}>
			<Layout>
				<Header style={{ background: '#fff', padding: 0, /*position: 'fixed'*/}}>
					<Navbar></Navbar>
				</Header>
				<SideMenu
					breakpoint="lg"
					collapsedWidth="0"
					side_menu={side_menu_info}
					style={{
						height: '100vh'
					}}
				// onBreakpoint={(broken) => {
				// 	console.log(broken);
				// }}
				// onCollapse={(collapsed, type) => {
				// 	console.log(collapsed, type);
				// }}
				/>
				<Layout>

					<Layout
						style={{
							marginTop: 75,
							padding: '0 10px 10px',
							bottom: 0,
							minHeight: '100%'
						}}
					>
						<Content
							style={{
								padding: 10,
								margin: 0,
								background: '#FFFFFF',
								minHeight: '65vh',
								bottom: 0,
							}}
						>
							<Routes>
								<Route element={<PersistLogin />}>
									<Route path="/" element={<Home items={side_menu_info} />} />
									<Route path="/termos_condicoes" element={<Terms/>} />
									

									{/*Opções da Home*/}
									<Route path="/loterias" element={<SubCategoria title={'loterias'} items={side_menu_info} />} />
									<Route path="/jogos" element={<SubCategoria title={'jogos'} items={side_menu_info} />} />
									<Route path="/aplicativos" element={<SubCategoria title={'aplicativos'} items={side_menu_info} />} />
									<Route path="/trade" element={<SubCategoria title={'trade'} items={side_menu_info} />} />
									<Route path="/novos_conceitos" element={<SubCategoria title={'novos_conceitos'} items={side_menu_info} />} />
									<Route path="/extensoes_chrome" element={<SubCategoria title={'extensoes_chrome'} items={side_menu_info} />} />
									<Route path="/apostas_esportivas" element={<SubCategoria title={'apostas_esportivas'} items={side_menu_info} />} />

									{/*Páginas com funcionalidades*/}
									<Route path="/loterias/desdobramentos" element={<DesdobramentosLoteria />} />
									<Route path="/loterias/analise_padroes" element={<AnalisPadr />} />
									<Route path="/loterias/geracao_combinacoes" element={<GerarCombin />} />
									<Route path="/loterias/preenchimento" element={<PreenchimLot />} />
									<Route path="/extensoes_chrome/preenchimento_loteria" element={<PreenchimLot />} />
									<Route path="/apostas_esportivas/estatisticas" element={<ApostasEsportivasEstatisticas />} />
									
									<Route path="/novos_conceitos/tls_screen" element={<TLSScreenPage />} />
									<Route path="/novos_conceitos/tls_factory" element={<TLSFactoryPage />} />
									<Route path="/ia/tls_text_speech" element={<TLSTextSpeechPage />} />									
									

									{/*Páginas relacionadas ao negócio*/}
									<Route path="/login" element={<Login />} />
									<Route path="/cadastro" element={<Cadastro />} />
									<Route path="/contato" element={<Contato />} />
									<Route path="/assinaturas" element={<Assinaturas />} />
									<Route path="/verificar_email" element={<VerificarEmail />} />
									<Route path="/confirm_email" element={<ConfirmEmail />} />
									<Route path="/redefinir_senha" element={<RedefSenha />} />
									<Route path="/alteracao_senha" element={<AlteracaoSenha />} />
									

									{/*Necessário estar logado (relacionado ao usuário)*/}
									<Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
										<Route path="/minhas_assinaturas" element={<MinhasAssinaturas />} />
										<Route path="/resgatar_cupom" element={<ResgatarCupom />} />
										<Route path="/assinar_plano" element={<AssinarPlano/>} />
										<Route path="/cadastro_info" element={<CadastroInfo/>} />										
									</Route>

									{/*Acessos de Admin*/}
									<Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
										<Route path="/admin" element={<Admin />} />
										<Route path="/admin/dashboard" element={<AdminDash />} />
										<Route path="/admin/loterias_desdobramentos" element={<DesdobramentosAdmin />} />
										<Route path="/admin/cupons" element={<Cupons />} />
									</Route>

									<Route element={<RequireAuth allowedRoles={[ROLES.Developer]} />}>
										<Route path="/dev" element={<Dev />} />
									</Route>

									<Route path='*' exact={true} element={<NotFound />} />

								</Route>
							</Routes>

						</Content>

					</Layout>
					<Footer
						style={{
							background: '#fff',
							bottom: 0,
							textAlign: 'center'
						}}
					>
						<div>TLS Engenharia - © {new Date().getFullYear()} - {t('todos_direitos')}</div>
						<br/>
						<Flex justify={'center'} align={'flex-start'} gap={'large'}>
							<Link to={window.location.origin.toString() + '/contato'}>{t('contato')}</Link>
							<Link to={window.location.origin.toString() + '/termos_condicoes'}>{t('termos_condicoes')}</Link>
						</Flex>
						<br/>
						<Flex justify={'center'} align={'flex-start'} gap={'large'}>
							<Link to={"https://www.instagram.com/tlsengenharia_"}  target="_blank"><InstagramOutlined style={{fontSize:32}}/></Link>
							<Link to={"https://www.youtube.com/@tlsengenharia"} target="_blank"><YoutubeOutlined style={{fontSize:32}}/></Link>
							<Link to={"https://www.tiktok.com/@tls.engenharia"}  target="_blank"><TikTokOutlined style={{fontSize:32}}/></Link>
						</Flex>
						
					</Footer>
				</Layout>

			</Layout>
			{!cookies.cookieConsent && <CookieConsent/>}
		</Spin>
	);

}

export default App;
