import React, { useState } from "react";
import { Select, Space, Table, Tooltip, Flex , Spin, Tag } from 'antd';
import { LoadingOutlined, InfoCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { list_subscriptions } from "../../services/index";
import { useTranslation } from "react-i18next";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAuth from '../../hooks/useAuth';
import { dateInDdMmYyyyHhMmSs, dateInDdMmYyyy } from "../../utils/format";

const MinhasAssinaturas = () => {

	window.scrollTo(0, 0);
	const axiosPrivate = useAxiosPrivate();
	
	const { auth, habRoles } = useAuth();

	const { t, i18n: { changeLanguage, language } } = useTranslation();

	const [loading_meus_planos, setLoadMeusPlan] = useState(true);
	const [dataSource, setDatasource] = useState([]);

	let api_exec = false;

	const columns = [
		{
			title: t("assinaturas_msg.status"),
			render: (_, record) => (
				<>{(record.plan_status == 0) ? <Tag color={'volcano'}>{t('assinaturas_msg.inativo')}</Tag> : <Tag color={'green'}>{t('assinaturas_msg.ativo')}</Tag>}</>
			   )
		},
		{
			title: t("assinaturas_msg.categoria"),
			//dataIndex: 'category'
			render: (_, record) => (
				<>{t(record.category)}</>
			   )
		},
		{
			title: t("assinaturas_msg.plano"),
			//dataIndex: 'subscription'
			render: (_, record) => (
				<>{t("assinaturas_msg."+record.subscription)}</>
			   )
		},		
		{
			title: t("assinaturas_msg.valido_desde"),
			//dataIndex: 'start_date'
			render: (_, record) => (
				<>{dateInDdMmYyyy(new Date(record.start_date),'/')}</>
			   )
		},
		{
			title: t("assinaturas_msg.valido_ate"),
			//dataIndex: 'end_date'
			render: (_, record) => (
				<>{dateInDdMmYyyy(new Date(record.end_date),'/')}</>
			   )
		},
		{
			title: t("assinaturas_msg.data_assinatura"),
			//dataIndex: 'create_date'
			render: (_, record) => (
				<>{dateInDdMmYyyyHhMmSs(new Date(record.create_date),'/')}</>
			   )
		},
		{
			title: t("assinaturas_msg.valor_pago"),
			//dataIndex: 'amount_paid'
			render: (_, record) => (
				<>{'R$ '+record.amount_paid.toFixed(2)}</>
			   )
		}		
	   ];

	   const getSubscriptions = async (em) => {
		
		try {
			api_exec = true;

			const response = await axiosPrivate.post('/UserSubscriptions', {
				em : auth?.user
			});

			if (response.status === 200) {
				if(response.data.length > 0){
					//console.log(JSON.parse(response.data[0].jsonInfo));
					setDatasource(()=>{
						return JSON.parse(response.data[0].jsonInfo);
					});
				}else{
					setDatasource(()=>{
						return [];
					});
				}

			} else {
				setDatasource(()=>{
					return [];
				});
			}
		} catch (err) {
			setDatasource(()=>{
				return [];
			});
		}
		setLoadMeusPlan(false);
	};

	if(loading_meus_planos === true){
		getSubscriptions(auth?.user);	
	}	

	return(
		<div>
			<Spin spinning={loading_meus_planos} indicator={<LoadingOutlined style={{ fontSize: 96}} spin />}>
				<h2 className="title is-1">{t('minhas_assinaturas')}</h2>
				<br/>
				<hr/>
				<br/>
				<Table id="tableList"					
					size="small"
					columns={columns} 
					dataSource={dataSource} 
					//onChange={onChange}
					pagination={{ pageSize: 20, size:"small", hideOnSinglePage:true/*,showSizeChanger: true */}}
					scroll={{
						x: 400,
					}}
				/>
			</Spin>
		</div>
	);
}


export default MinhasAssinaturas;