import React, { useState, useEffect, useRef } from 'react';
import { Collapse, Tabs, Select, List, Divider, Avatar, Skeleton, Form, Input, Button, Typography, Popover, Flex, Upload } from 'antd';
import { UserOutlined, PlusCircleOutlined, DownloadOutlined, PlayCircleOutlined, SaveOutlined, UploadOutlined, DeleteOutlined, SettingOutlined } from '@ant-design/icons';
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { Route, Link, Routes, useLocation, useNavigate } from 'react-router-dom';
import { SpeechConfig, SpeechSynthesizer, SpeechSynthesisResult, SpeechService, SpeechSynthesisOutputFormat, ResultReason } from 'microsoft-cognitiveservices-speech-sdk';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from "react-i18next";
import './css/index.css';
const { encode } = require('wav-encoder');
const { Buffer } = require('buffer');
const { Paragraph, Text, Title } = Typography;

const TLSTextSpeech = () => {

	window.scrollTo(0, 0);

	const { t, i18n: { changeLanguage, language } } = useTranslation();

	const [voz, setVoz] = useState("pt-BR-AntonioNeural");
	const [ellipsis, setEllipsis] = useState(true);
	const [text_add, setTextAdd] = useState("");
	const [audio_list, setAudioList] = useState({
		"novos": [],
		"salvos": [],
		"player_list": []
	});

	const voice_items = [
		{
			"key": 1,
			"label": <>Andrew - en-US</>,
			"lang": "en-US",
			"value": "en-US-AndrewMultilingualNeural"
		},
		{
			"key": 2,
			"label": <>Jenny - en-US</>,
			"lang": "en-US",
			"value": "en-US-JennyNeural"
		},
		{
			"key": 3,
			"label": <>Antonio - pt-BR</>,
			"lang": "pt-BR",
			"value": "pt-BR-AntonioNeural"
		},
		{
			"key": 4,
			"label": <>Francisca - pt-BR</>,
			"lang": "pt-BR",
			"value": "pt-BR-FranciscaNeural"
		}
	];

	const handleChangeVoz = (value) => {
		setVoz(value);
	};

	const add_novo_text = () => {

		let new_novos = audio_list.novos;
		new_novos.unshift({
			"voz": voz,
			"titulo": "",
			"texto": text_add,
			"cod": "",
			"download": null
		});
		setAudioList({ ...audio_list, novos: new_novos });
	};

	const DownloadAddText = () => {
		if (text_add !== '') {
			const audioFile = 'text_to_speech.wav';
			synthesizer.speakTextAsync(text_add, result => {

				synthesizer.synthesisCompleted = (s, e) => {

					const audioBuffer = result.audioData;
					const blob = new Blob([audioBuffer], { type: 'audio/wav' });

					let new_novos = audio_list.novos;
					new_novos.unshift({
						//"key": new_novos.length + 1,
						"voz": voz,
						"titulo": "",
						"texto": text_add,
						"cod": "",
						"download": blob
					});
					setAudioList({ ...audio_list, novos: new_novos });

					const url = URL.createObjectURL(blob);
					const a = document.createElement('a');
					a.href = url;
					a.download = audioFile;
					a.click();

				};
			});
		}
	};

	const DownloadListNovaText = (blob) => {
		const audioFile = 'text_to_speech.wav';
		const url = URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = audioFile;
		a.click();
	};

	const PlayListNovaText = (blob) => {
		const url = URL.createObjectURL(blob);
		const audioElement = new Audio(url);
		audioElement.play();
	};

	const SaveListNovaText = (blob) => {
		alert('Em desenvolvimento');
	};

	const ExcluirListNovaText = (index) => {
		let new_novos = audio_list.novos;
		new_novos.splice(index, 1);

		setAudioList({ ...audio_list, novos: new_novos });
	};

	const GerarListNovaText = (index) => {
		//setTextAdd(audio_list.novos[index].texto);

		let text_add2 = audio_list.novos[index].texto;
		if (text_add2 !== '') {
			const audioFile = 'text_to_speech.wav';
			synthesizer.speakTextAsync(text_add2, result => {

				synthesizer.synthesisCompleted = (s, e) => {

					const audioBuffer = result.audioData;
					const blob = new Blob([audioBuffer], { type: 'audio/wav' });

					let new_novos = audio_list.novos;
					new_novos[index].download = blob;
					setAudioList({ ...audio_list, novos: new_novos });

					const url = URL.createObjectURL(blob);
					const a = document.createElement('a');
					a.href = url;
					a.download = audioFile;
					a.click();

				};
			});
		}
	};

	const DownloadListVttText = (blob) => {
		const audioFile = 'text_to_speech.wav';
		const url = URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = audioFile;
		a.click();
	};

	const PlayListVttText = (blob) => {
		const url = URL.createObjectURL(blob);
		const audioElement = new Audio(url);
		audioElement.play();
	};

	const SaveListVttText = (blob) => {
		alert('Em desenvolvimento');
	};

	const ExcluirListVttText = (index) => {
		//console.log(index);  
		let new_player_list = audio_list.player_list;
		new_player_list.splice(index, 1);

		setAudioList({ ...audio_list, player_list: new_player_list });
	};

	const GerarListVttText = (index) => {
		let text_add2 = audio_list.player_list[index].texto;
		//setTextAdd(audio_list.player_list[index].texto);
		if (text_add2 !== '') {
			const audioFile = 'text_to_speech.wav';
			synthesizer.speakTextAsync(text_add2, result => {

				synthesizer.synthesisCompleted = (s, e) => {

					const audioBuffer = result.audioData;
					const blob = new Blob([audioBuffer], { type: 'audio/wav' });

					let new_player_list = audio_list.player_list;
					new_player_list[index].download = blob;
					setAudioList({ ...audio_list, player_list: new_player_list });

					const url = URL.createObjectURL(blob);
					const a = document.createElement('a');
					a.href = url;
					a.download = audioFile;
					a.click();

				};
			});
		}
	};

	const tab_text_items = [
		{
			key: '1',
			label: 'Novos',
			children: <>
				<h3>Digite o texto aqui</h3>
				<Input.TextArea onChange={(e) => setTextAdd(e.target.value.trim())} autoSize={{ minRows: 2, maxRows: 10 }} maxLength={4000} />
				<br />
				<br />
				<Button icon={<PlusCircleOutlined />} onClick={add_novo_text} disabled={text_add == ""}>Adicionar</Button>
				<Button icon={<DownloadOutlined />} onClick={DownloadAddText} disabled={text_add == ""}>Download</Button>
				<br />
				<br />
				{audio_list.novos.length > 0 && <>
					<List
						bordered
						pagination={{
							position: 'bottom',
							align: 'center',
							hideOnSinglePage: true
						}}
						footer={null}
						dataSource={audio_list.novos}
						renderItem={(item, index) => (
							<List.Item>
								<Paragraph
									ellipsis={
										ellipsis
											? {
												rows: 2,
												expandable: true
											}
											: false
									}
								>
									{item.texto}
								</Paragraph>
								<Popover
									placement="left"

									content={<>
										<Flex vertical={'vertical'} justify='start' gap="middle">
											{item.download === null && <a onClick={() => { GerarListNovaText(index) }} style={{ border: 'none' }}><UploadOutlined style={{ fontSize: 22 }} /> Gerar</a>}
											{item.download !== null && <a onClick={() => { DownloadListNovaText(item.download) }} style={{ border: 'none' }}><DownloadOutlined style={{ fontSize: 22 }} /> Download</a>}
											{item.download !== null && <a onClick={() => { PlayListNovaText(item.download) }} style={{ border: 'none' }}><PlayCircleOutlined style={{ fontSize: 22 }} /> Ouvir</a>}
											{item.download !== null && <a onClick={() => { SaveListNovaText(item.download) }} style={{ border: 'none' }}><SaveOutlined style={{ fontSize: 22 }} /> Salvar</a>}
											<a size={'large'} onClick={() => { ExcluirListNovaText(index) }} style={{ border: 'none' }}><DeleteOutlined style={{ fontSize: 22 }} /> Excluír</a>
										</Flex></>} trigger="click">
									<Button shape="circle" icon={<SettingOutlined />} size={'large'} />
								</Popover>
							</List.Item>
						)}
					/>
				</>}
			</>,
		},
		{
			key: '2',
			label: 'Salvos',
			children: <>

			</>
		}
	];

	const fileSelect = useRef();
	const handleFileChange = (event) => {
		handleConvert(event.target.files[0]);
	};

	const handleSpeak = () => {

		//audioContext = new AudioContext();
		//sampleRate = audioContext.sampleRate;
		//console.log("audioContext",audioContext);
		//console.log("sampleRate",sampleRate);

		audio_list.player_list.map((text_legenda, index) => {
			if (index < 3) {
				synthesizer.speakTextAsync(text_legenda.texto, result => {
					// ...
					const audioBuffer = result.audioData;
					const blob = new Blob([audioBuffer], { type: 'audio/wav' });
					const reader = new FileReader();

					reader.onload = (event) => {
						const arrayBuffer = event.target.result;
						const audioContext = new AudioContext();
						audioContext.decodeAudioData(arrayBuffer, (buffer) => {
							const samples = buffer.getChannelData(0);
							const floatSamples = new Float32Array(samples.length);
							for (let i = 0; i < samples.length; i++) {
								floatSamples[i] = samples[i];
							}
							const wavBuffer = encode({
								sampleRate: buffer.sampleRate,
								bitDepth: 16,
								channels: 1,
								samples: floatSamples
							});
							const wavBlob = new Blob([wavBuffer], { type: 'audio/wav' });
							const url = URL.createObjectURL(wavBlob);
							const a = document.createElement('a');
							a.href = url;
							a.download = 'merged_audio.wav';
							a.click();
						});
					};



					//const arrayBuffer = event.target.result;
					// 		    audioContext.decodeAudioData(arrayBuffer, (decodedBuffer) => {
					// 			 if (decodedBuffer) {
					// 			   if (mergedBuffer === null) {
					// 				mergedBuffer = decodedBuffer;
					// 			   } else {
					// 				const newBuffer = audioContext.createBuffer(
					// 				  decodedBuffer.numberOfChannels,
					// 				  mergedBuffer.length + decodedBuffer.length,
					// 				  audioContext.sampleRate
					// 				);
					// 				for (let i = 0; i < decodedBuffer.numberOfChannels; i++) {
					// 				  newBuffer.getChannelData(i).set(mergedBuffer.getChannelData(i));
					// 				  newBuffer.getChannelData(i).set(decodedBuffer.getChannelData(i), mergedBuffer.length);
					// 				}
					// 				mergedBuffer = newBuffer;
					// 			   }
					// 			   console.log('Tamanho do mergedBuffer:', mergedBuffer.length);
					// 			   if (index === 2) {
					// 				const wavHeader = new Uint8Array(44);
					// 				wavHeader.set([82, 73, 70, 70, 36, 0, 0, 0, 87, 65, 86, 69, 102, 109, 116, 32, 16, 0, 0, 0, 1, 0, 1, 0, 68, 172, 0, 0, 136, 88, 1, 0, 2, 0, 16, 0, 100, 97, 116, 97, 36, 0, 0, 0]);
					// 				console.log('Conteúdo do cabeçalho do arquivo WAV:', wavHeader);
					// 				const wavData = new Uint8Array(mergedBuffer.length * mergedBuffer.numberOfChannels);
					// 				for (let i = 0; i < mergedBuffer.length; i++) {
					// 				  for (let j = 0; j < mergedBuffer.numberOfChannels; j++) {
					// 				    wavData[i * mergedBuffer.numberOfChannels + j] = Math.floor(mergedBuffer.getChannelData(j)[i] * 32767);
					// 				  }
					// 				}
					// 				// Atualizar o cabeçalho do arquivo WAV com a duração do áudio
					// 				const fileSize = wavData.length + 36;
					// 				wavHeader[4] = fileSize & 0xFF;
					// 				wavHeader[5] = (fileSize >> 8) & 0xFF;
					// 				wavHeader[6] = (fileSize >> 16) & 0xFF;
					// 				wavHeader[7] = (fileSize >> 24) & 0xFF;

					// 				const sampleCount = mergedBuffer.length * mergedBuffer.numberOfChannels;
					// const byteRate = sampleCount * 2;
					// wavHeader[24] = (sampleCount / sampleRate) & 0xFF;
					// wavHeader[25] = (((sampleCount / sampleRate) >> 8) & 0xFF);
					// wavHeader[26] = (((sampleCount / sampleRate) >> 16) & 0xFF);
					// wavHeader[27] = (((sampleCount / sampleRate) >> 24) & 0xFF);

					// 				const duration = sampleCount / sampleRate;
					// 				const durationBytes = new Uint8Array(4);
					// 				durationBytes[0] = (duration & 0xFF);
					// 				durationBytes[1] = ((duration >> 8) & 0xFF);
					// 				durationBytes[2] = ((duration >> 16) & 0xFF);
					// 				durationBytes[3] = ((duration >> 24) & 0xFF);
					// 				wavHeader[24] = durationBytes[0];
					// 				wavHeader[25] = durationBytes[1];
					// 				wavHeader[26] = durationBytes[2];
					// 				wavHeader[27] = durationBytes[3];

					// 				const blockAlign = 2;
					// 				wavHeader[32] = blockAlign & 0xFF;
					// 				wavHeader[33] = (blockAlign >> 8) & 0xFF;
					// 				const bitsPerSample = 16;
					// 				wavHeader[34] = bitsPerSample & 0xFF;
					// 				wavHeader[35] = (bitsPerSample >> 8) & 0xFF;

					// 				console.log('Conteúdo do cabeçalho do arquivo WAV após atualização:', wavHeader);
					// 				const wavBlob = new Blob([wavHeader, wavData], { type: 'audio/wav' });
					// 				const url = URL.createObjectURL(wavBlob);
					// 				const a = document.createElement('a');
					// 				a.href = url;
					// 				a.download = 'merged_audio.wav';
					// 				a.click();
					// 				console.log('Áudio mesclado com sucesso!');
					// 			   }
					// 			 } else {
					// 			   console.error('Erro ao decodificar áudio:', arrayBuffer);
					// 			 }
					// 		    }, (error) => {
					// 			 console.error('Erro ao decodificar áudio:', error);
					// 		    });
					//};

					reader.readAsArrayBuffer(blob);
				});
			}
		});
	};

	const collapse_items = [
		{
			key: '1',
			label: 'Vozes',
			children: <>
				<Select
					onChange={handleChangeVoz}
					defaultValue={voz}
					style={{ minWidth: "200px" }}
					options={voice_items}
				>
				</Select>
			</>,
		},
		{
			key: '2',
			label: 'Textos',
			children: <>
				<Tabs
					//onChange={onChange}
					type="card"
					items={tab_text_items}
				/>
			</>,
		},
		{
			key: '3',
			label: 'Arquivo VTT',
			children: <>
				<Button icon={<UploadOutlined />} onClick={() => fileSelect.current.click()}>Upload VTT</Button>
				<input
					type='file'
					ref={fileSelect}
					onChange={handleFileChange}
					style={{ display: "none" }}
				/>
				{audio_list.player_list.length > 0 && <Button icon={<DownloadOutlined />} onClick={handleSpeak}>Gerar audio</Button>}
				<br />
				<br />
				{audio_list.player_list.length > 0 && <>
					<List
						bordered
						pagination={{
							position: 'bottom',
							align: 'center',
							hideOnSinglePage: true
						}}
						footer={null}
						dataSource={audio_list.player_list}
						renderItem={(item, index) => (
							<List.Item>
								<Flex vertical>
									<Title level={5}>{item.start + " - " + item.end}</Title>
									<Paragraph
										ellipsis={
											ellipsis
												? {
													rows: 2,
													expandable: true
												}
												: false
										}
									>
										{item.texto}
									</Paragraph>
								</Flex>
								<Popover
									placement="left"

									content={<>
										<Flex vertical={'vertical'} justify='start' gap="middle">
											{item.download === null && <a onClick={() => { GerarListVttText(index) }} style={{ border: 'none' }}><UploadOutlined style={{ fontSize: 22 }} /> Gerar</a>}
											{item.download !== null && <a onClick={() => { DownloadListVttText(item.download) }} style={{ border: 'none' }}><DownloadOutlined style={{ fontSize: 22 }} /> Download</a>}
											{item.download !== null && <a onClick={() => { PlayListVttText(item.download) }} style={{ border: 'none' }}><PlayCircleOutlined style={{ fontSize: 22 }} /> Ouvir</a>}
											{item.download !== null && <a onClick={() => { SaveListVttText(item.download) }} style={{ border: 'none' }}><SaveOutlined style={{ fontSize: 22 }} /> Salvar</a>}
											<a size={'large'} onClick={() => { ExcluirListVttText(index) }} style={{ border: 'none' }}><DeleteOutlined style={{ fontSize: 22 }} /> Excluír</a>
										</Flex></>} trigger="click">
									<Button shape="circle" icon={<SettingOutlined />} size={'large'} />
								</Popover>
							</List.Item>
						)}
					/>
				</>}
			</>,
		},
	];

	const [loading, setLoading] = useState(false);
	const loadData = () => {
		if (loading) {
			return;
		}
		setLoading(true);

		//Ao executar
		setLoading(false);
	};

	useEffect(() => {
		loadData();
	}, []);

	//Audio
	const audioFile = 'output.wav';
	const mp3File = 'output.mp3';

	const speechConfig = SpeechConfig.fromSubscription('5235d2fe1bed49cc8e41c375b13b1635', 'brazilsouth');
	speechConfig.speechSynthesisVoiceName = voz; // Select the voice model
	speechConfig.speechSynthesisOutputFormat = SpeechSynthesisOutputFormat.Audio48Khz96KBitRateStereoWav;

	//const format = SpeechSynthesisOutputFormat.Audio48Khz96KBitRateStereoWav;//Audio16Khz32KBitRateMonoWav;
	const synthesizer = new SpeechSynthesizer(speechConfig, null);

	//Merged audio
	const [audioFiles, setAudioFiles] = useState([]);
	const [mergedAudio, setMergedAudio] = useState(null);
	const audioFilesList = [];
	const interval = 3 * 60 * 1000; // 3 minutos

	let audioContext = null;
	let sampleRate = null;
	let mergedBuffer = null;

	const handleConvert = (vttFile) => {
		if (vttFile) {
			const reader = new FileReader();
			reader.onload = (event) => {
				const vttText = event.target.result;
				const json = vttToJSON(vttText);
				setAudioList({ ...audio_list, player_list: json });
			};
			reader.readAsText(vttFile);
		}
	};

	const vttToJSON = (vttText) => {
		let prox_lin = false;
		const lines = vttText.split('\n');
		const cues = [];
		let currentCue = null;

		lines.forEach((line) => {
			if (line.startsWith('WEBVTT')) {
				return;
			}

			if (line.trim() === '') {
				return;
			}

			if (line.startsWith('NOTE')) {
				return;
			}

			if (line.includes('-->')) {
				const [startTime, endTime] = line.split('-->');
				const cue = {
					"key": cues.length,
					"voz": voz,
					"titulo": "",
					"start": startTime.trim(),
					"end": endTime.trim(),
					"texto": "",
					"cod": "",
					"download": null
				};

				prox_lin = true;
				currentCue = cue;
				cues.push(cue);
			} else {
				if (currentCue && prox_lin == true) {
					currentCue.texto += line;
					prox_lin = false;
				}
			}
		});
		return cues;
	};

	return (
		<>
			<Collapse items={collapse_items} defaultActiveKey={['1', '2', '3']} />
		</>
	);
}

export default TLSTextSpeech;