import React, { useRef, useState, useEffect } from 'react';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Alert } from 'antd';
import { Route, Link, Routes, useLocation, useNavigate } from 'react-router-dom';
//import AuthContext from "../context/AuthProvider";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useTranslation } from "react-i18next";
import useAuth from '../../hooks/useAuth';
import bcrypt from 'bcryptjs';
import { jwtDecode } from "jwt-decode";

const Login = () => {

	window.scrollTo(0, 0);

	const { t, i18n: {changeLanguage, language} } = useTranslation();

	const axiosPrivate = useAxiosPrivate();
	const navigate = useNavigate();
	const location = useLocation();
	const from = (location.state?.from?.pathname) ? location.state?.from?.pathname + ((location.state?.search) ? location.state?.search : "") : "/" +((location.state?.search) ?  + location.state?.search : "");

	const { setAuth, persist, setPersist, setSessionId, hs } = useAuth();
	const errRef = useRef();
	const emailRef = useRef();

	useEffect(() => {
		emailRef.current.focus();
	 }, []);

	const [form] = Form.useForm();
	// const onFinish = async (values) => {
	// 	setAuth({ values });
	// 	console.log('Received values of form: ', values);
	// };

	const [email, setEmail] = useState('');
	const [pwd, setPwd] = useState('');

	const [validEmail, setValidEmail] = useState(false);
	const [validPwd, setValidPwd] = useState(false);

	const [errMsg, setErrMsg] = useState('');

	useEffect(() => {
		setErrMsg('');
		setValidEmail(email !== "");
		setValidPwd(PWD_REGEX.test(pwd));
	}, [email, pwd])

	const SubmitButton = ({ form, children, on_click }) => {
		const [submittable, setSubmittable] = React.useState(false);

		// Watch all values
		const values = Form.useWatch([], form);
		React.useEffect(() => {
			form
				.validateFields({
					validateOnly: true,
				})
				.then(() => setSubmittable(true))
				.catch(() => setSubmittable(false));
		}, [form, values]);

		return (
			<Button type="primary" htmlType="submit" disabled={!submittable} onClick={on_click}>
				{children}
			</Button>
		);
	};

	const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

	const handleSubmit = async (e) => {
		e.preventDefault();

		const v1 = email !== "";
		const v2 = PWD_REGEX.test(pwd);
		const hashedPassword = bcrypt.hashSync(pwd, hs);

		if (!v1 || !v2) {
			setErrMsg("Preenchimento inválido!");
			return;
		}

		try {
			const response = await axiosPrivate.post('/PageInfo', { em: email, sn: hashedPassword, ps :  true});

			if (response.status === 200) {

				if (response.data !== null && response.data !== undefined && response.data !== "") {
		
					switch(response.data.error_code) {
						case 0:

							//console.log('retorno OK');
							//setSuccess(true);
							//form.resetFields();
							//setErrMsg('login OK');
							//setAuth(return_info[0]);
							//setAuth({ user: user, info: response.data, menu_info: menu_info });
							setAuth({ user: response.data.user, access_token: response.data.access_token, menu_info: response.data.menu_info })
							//console.log(response.data);
							//if(persist == true){
								setSessionId(response.data.session_id);
							//}else{
							//	setSessionId('');
							//}

							//navigate('/');
							navigate(from, {replace: true});
							break;
							//sucRef.current.focus();
						case 1:
							setErrMsg('login_msg.email_senha_inv');
							//setSuccess(false);
							break;
						case 2:
							setErrMsg('login_msg.numero_tentativas');
							//setSuccess(false);
							break;
						case 3:
							setErrMsg('login_msg.pendente_conf_email');
							//setSuccess(false);
							break;
						default:
							setErrMsg('login_msg.error_4');
							//setSuccess(false);
					 }

				}else{
					setErrMsg('login_msg.error_5');
					//setSuccess(false);
				}

			} else {
				setErrMsg('login_msg.error_6');
				//setSuccess(false);
			}

		} catch (err) {
			if (!err?.response) {
				setErrMsg('login_msg.error_7');
				//setSuccess(false);
			} else if (err.response?.status === 409) {
				setErrMsg('login_msg.error_8');
				//setSuccess(false);
			} else {
				setErrMsg('login_msg.error_9');
				//setSuccess(false);
			}
		}
	}

	const togglePersist = () => {
		setPersist(prev => !prev);
	 }
  
	 useEffect(() => {
		localStorage.setItem("persist", persist);
	 }, [persist])

	return (
		<div>
			<h2 className="title is-1">{t('login')}</h2>
			<br />
			<hr />
			<br />
			<div
				style={{
					//height: 300, 
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}>
				<Form
					form={form}
					style={{
						maxWidth: 320,
					}}
					scrollToFirstError
					name="normal_login"
					initialValues={{
						remember: true,
					}}
				//onFinish={onFinish}
				>

					{errMsg !== "" ? (
						<>
							<Alert 
								ref={errRef} 
								message={t(errMsg)}
								action={
									errMsg == "login_msg.pendente_conf_email" ?
									<><Button size="small" type="primary" onClick={()=> {navigate('/verificar_email')}}>
										{t('valid_msg.reenviar')}
								   </Button></> : <></>
								}
								type="error" showIcon /><br />
						</>
					) : (<></>)}

					<Form.Item
						name="email"
						label={t('cadastro_msg.email')}
						onChange={(e) => setEmail(e.target.value)}
						rules={[
							{
								required: true,
								type: 'email',
								message: t('cadastro_msg.email_err'),
							},
							// {
							// 	required: true,
							// 	message: 'Por favor, digite um e-mail',
							// },
						]}
					>
						<Input ref={emailRef}/>
					</Form.Item>

					<Form.Item
						name="password"
						label={t('cadastro_msg.senha')}
						onChange={(e) => setPwd(e.target.value)}
						rules={[
							{
								required: true,
								message: t('cadastro_msg.falta_senha'),
							},
						]}
					>
						<Input.Password />
					</Form.Item>

					<Form.Item
						name="remember"
						//valuePropName=""
					>
						<Checkbox
							id="persist"
							onChange={togglePersist}
							checked={persist}
						>{t('login_msg.lembrar')}</Checkbox>
					</Form.Item>
					{/* <div className="persistCheck">
                    <input
                        type="checkbox"
                        id="persist"
                        onChange={togglePersist}
                        checked={persist}
                    />
                    <label htmlFor="persist">Trust This Device</label>
                </div> */}

					<Form.Item>
						<Link className="login-form-forgot" to={window.location.origin.toString() + '/alteracao_senha'}> {t('login_msg.esqueci_senha')} </Link>
					</Form.Item>
					<Form.Item>
						<SubmitButton disabled={!validEmail || !validPwd ? true : false} form={form} on_click={handleSubmit}>{t('login')}</SubmitButton>  {t('ou')}  <Link to={"/cadastro"}>{t('cadastro')}</Link>
					</Form.Item>
				</Form>
			</div>
		</div>
	)
};

export default Login