import React from "react";
import '../../styles/planos.css';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from "react-i18next";
import SubscribeCard from "../../components/SubscribeCard";
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react'
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Select, Space, Table, Tooltip, Flex, Spin, Descriptions, Form, Input, Alert  } from 'antd';
import { LoadingOutlined, InfoCircleOutlined, DownloadOutlined, StopOutlined } from '@ant-design/icons';
import { dateInDdMmYyyyHhMmSs, dateInDdMmYyyy } from "../../utils/format";

const AssinarPlano = () => {

	window.scrollTo(0, 0);

	const { t, i18n: { changeLanguage, language } } = useTranslation();
	const { auth } = useAuth();
	const axiosPrivate = useAxiosPrivate();

	if(process.env.NODE_ENV === 'production'){
		initMercadoPago('APP_USR-5084f5b1-2629-4dab-9212-f5db0029dc55'); //PROD
	}else{
		initMercadoPago('TEST-0021208a-d05b-4116-9429-489c85ce9678'); //DEV
	}

	const [errMsg, setErrMsg] = useState('');
	const [alertMsg, setAlertMsg] = useState('');

	const [loading_assinatura_info, setLoadAssinInfo] = useState(false);
	const [pref_info, setPrefInfo] = useState({});
	let exec_api = false;

	useEffect(() => {
		
		setLoadAssinInfo(true);

		const queryParams = new URLSearchParams(window.location.search)
    		const sb = queryParams.get("sb");

		if(exec_api == false){
			exec_api = true;
			getInfoAssinatura(auth?.user, sb);
		}
		
	}, []);

	const getInfoAssinatura = async (em, sb) => {

		try {
			const response = await axiosPrivate.post('/PostMPPref', {
				em: em,
				sb: sb
			});

			if (response.status === 200) {
				//console.log(response.data);
				if(response.data.error_code === 0){
					// setPrefId(() => {
					// 	//console.log(response.data.preferenceId);
					// 	return response.data.preferenceId
					// });
					setAlertMsg("");
					setErrMsg("");

					setPrefInfo(() =>{
						return response.data
					})
				}else{
					setAlertMsg("assinaturas_msg.erro_assinatura_ativa");
					setErrMsg("");
				}				
			} else {
				// setDatasource(()=>{
				// 	return [];
				// });
				setAlertMsg("");
				setErrMsg("assinaturas_msg.erro_gerar_link");
			}
		} catch (err) {
			// setDatasource(()=>{
			// 	return [];
			// });
			setAlertMsg("");
			setErrMsg("assinaturas_msg.erro_gerar_link");
		}
		setLoadAssinInfo(false);
		//console.log(pref_id);
	};

	return (
		<div>
			<Spin spinning={loading_assinatura_info} indicator={<LoadingOutlined style={{ fontSize: 96 }} spin />}>
				<h2 className="title is-1">{t('assinaturas')}</h2>
				<br />
				<hr />
				<br />
				<br />
				{errMsg != "" ? (
					<><Alert message={t(errMsg)} type="error" showIcon /><br/></>
				) : (<></>)}
					
				{alertMsg != "" ? (
					<><Alert message={t(alertMsg)} type="warning" showIcon/><br/></>
				) : (<></>)}

				<div style={{width:"100%"}}>
					<div style={{ maxWidth: 300, margin:"auto" }}>
						{pref_info?.preferenceId !== "" && pref_info?.preferenceId != undefined ? (
							<>
								<h2>{t('assinaturas_msg.resumo')}</h2>
								<br/>
								<Form
									labelCol={{
									span: 8,
									}}
									wrapperCol={{
									span: 18,
									}}
									layout="horizontal"
									// style={{
									// maxWidth: 600,
									// }}
								>
									<Form.Item label={t('assinaturas_msg.descricao')}><Input value={pref_info?.title} readOnly={true}/></Form.Item>
									<Form.Item label={t('assinaturas_msg.valor')}><Input value={"R$ "+pref_info?.unitPrice.toFixed(2)} readOnly={true}/></Form.Item>
									<Form.Item label={t('assinaturas_msg.pagar_ate')}><Input value={dateInDdMmYyyyHhMmSs(new Date(pref_info?.dateOfExpiration))} readOnly={true}/></Form.Item>
								</Form>
								<Wallet initialization={{ preferenceId: pref_info?.preferenceId }} />
							</>) 
							: 
							<></>
						}					
					</div>
				</div>
			</Spin>
		</div>
	);
}

export default AssinarPlano;