import React from "react";
import { useTranslation } from "react-i18next";
import YoutubeEmbed from "../components/YoutubeEmbed";

const Contato = () => {

	window.scrollTo(0, 0);
	const { t, i18n: {changeLanguage, language} } = useTranslation();

	return(
		<div>
			<h2 className="title is-1">{t("contato")}</h2>
			<br/>
			<hr/>
			<br/>
			{/* <YoutubeEmbed embedId="rokGy0huYEA"/>
			<YoutubeEmbed embedId="rokGy0huYEA"/> */}
			<p style={{fontSize:16,lineHeight:'1.5em', textAlign:"justify"}}>{t("contato_msg.contato_txt")}</p>
			<br/>
			<br/>
			<br/>
			<br/>
			<br/>
			<h3>{t("cadastro_msg.email")}:</h3>
			{/* <p>contato@tlsengenharia.com.br</p> */}
			<a href="mailto:contato@tlsengenharia.com.br">contato@tlsengenharia.com.br</a>
		</div>
	);
}


export default Contato;